<template>
    <div class="d-md-flex align-items-center justify-content-between mb-2 mt-2">
        <div>
            <h4 class="main-title mb-0">Muddemal</h4>
        </div>
        <div class="d-flex gap-2 mt-3 mt-md-0">
            <div class="d-flex gap-2 mt-3 mt-md-0 flex-row-reverse">
                <!-- <label for="search" class="form-label">Search</label> -->
                <input type="email" class="form-control" id="search" placeholder="Search" v-model="meta.search"
                    @keyup="search()" />
            </div>
            <button @click="reset()" class="btn btn-success"><i class="ri-restart-line fs-18 lh-1"></i> Reset</button>
            <button @click="downloadMuddemals()" class="btn btn-warning"><i class="ri-download-2-line fs-18 lh-1"></i>
                Export</button>
            <router-link to="/muddemal_create" class="btn btn-primary" @click="setPage()"> <i
                    class="ri-add-line fs-18 lh-1"></i> Create </router-link>
        </div>
    </div>
    <div class="row g-3 mb-2">
        <div class="col-md-3">
            <label class="form-label">Division</label>
            <select class="form-select" v-model="meta.division_id" @change="obtainSubDivisions()">
                <option value="">Select</option>
                <option v-for="division, key in divisions" :value="division.division_id">
                    {{ division.division }}</option>
            </select>
        </div>
        <div class="col-md-3">
            <label class="form-label">Sub Division</label>
            <select class="form-select" v-model="meta.sub_division_id" :disabled="!meta.division_id" @change="search()">
                <option value="">Select</option>
                <option v-for="sub_division, key in sub_divisions" :value="sub_division.sub_division_id">{{
                    sub_division.sub_division }}</option>
            </select>
        </div>
        <div class="col-md-3 d-block">
            <label class="form-label">Police Station</label>
            <MultiSelect v-model="selected_police_stations" filter optionLabel="police_station"
                :options="police_stations" placeholder="Select Police Station" :maxSelectedLabels="3" class=" "
                style="height: 35px; width: 100%;" @change="PoliceStaionFilter(selected_police_stations)" />
        </div>
        <div class="col-md-3">
            <label class="form-label">Year</label>
            <MultiSelect v-model="selected_years" filter optionLabel="year" :options="years" placeholder="Select Year"
                :maxSelectedLabels="3" class=" " style="height: 35px; width: 100%;"
                @change="yearFilter(selected_years)" />
        </div>
        <div class="col-md-2">
            <label class="form-label">Case Stage</label>
            <select class="form-control" v-model="meta.case_stage_id" @change="search()">
                <option value="">Select Case Stage</option>
                <option v-for="case_stage, key in case_stages" :key="key" :value="case_stage.case_stage_id">
                    {{ case_stage.case_stage }}</option>
                <option value="null">Null</option>
            </select>
        </div>
        <div class="col-md-2">
            <label class="form-label">Is Disposed?</label>
            <select class="form-select" v-model="meta.disposed" @change="search()">
                <option value="">Select</option>
                <option :value="true">Yes</option>
                <option :value="false">No</option>
                <option value="null">Null</option>
            </select>
        </div>
        <div class="col-md-4" v-show="meta.disposed">
            <label class="form-label">Disposal Stages</label>
            <select class="form-control" v-model="meta.property_disposal_stage_id" @change="search()">
                <option value="">Select Disposal Stage</option>
                <option v-for="disposal_stage, key in disposal_stages" :key="key"
                    :value="disposal_stage.property_disposal_stage_id">
                    {{ disposal_stage.property_disposal_stage }}</option>
            </select>
        </div>
        <div class="col-md-2">
            <label class="form-label">Is Available?</label>
            <select class="form-select" v-model="meta.is_available" @change="search()">
                <option value="">Select</option>
                <option :value="true">Yes</option>
                <option :value="false">No</option>
            </select>
        </div>
        <div class="col-md-2">
            <label class="form-label">Is Valuable?</label>
            <select class="form-select" v-model="meta.is_valuable" @change="search()">
                <option value="">Select</option>
                <option value="Valuable">Yes</option>
                <option value="Non-Valuable">No</option>
            </select>
        </div>
    </div>
    <div class="row">
        <div class="col-xl-12">
            <div class="card card-one">
                <div class="card-body">
                    <div class="d-flex justify-content-between">
                        <h5 class="mb-2" v-if="meta.police_station_id"><span>{{ police_station_name }} Police station
                                Muddemal details</span> <span v-if="meta.year.length"> for the year
                                {{ selected_year }}</span></h5>
                        <h5 class="mb-2" v-else-if="!meta.police_station_id && meta.year.length"><span> Muddemal details
                                for the year {{ selected_year }}</span></h5>
                        <h5 class="mb-2" v-else>All</h5>
                        <h6 class="text-primary pt-1 mb-2"><span> Total Muddemals : {{ meta.totalRows }}</span></h6>
                    </div>

                    <div class="table-responsive" style="overflow-y: scroll; height: 40vh;">
                        <table class="table table-sticky table-bordered table-hover table-bottom-border mb-4">
                            <thead>
                                <tr style="background-color: #f5f5f5;">
                                    <th class="text-center text-nowrap">Sl No.</th>
                                    <th width="800%" @click="sort('year')">
                                        Year
                                        <span>
                                            <i v-if="meta.keyword == 'year' && meta.order_by == 'asc'"
                                                class="ri-arrow-up-line"></i>
                                            <i v-else-if="meta.keyword == 'year' && meta.order_by == 'desc'"
                                                class="ri-arrow-down-line"></i>
                                            <i v-else class="fas fa-sort"></i>
                                        </span>
                                    </th>
                                    <th @click="sort('pf_date')" class="text-nowrap">
                                        PF Date
                                        <span>
                                            <i v-if="meta.keyword == 'pf_date' && meta.order_by == 'asc'"
                                                class="ri-arrow-up-line"></i>
                                            <i v-else-if="meta.keyword == 'pf_date' && meta.order_by == 'desc'"
                                                class="ri-arrow-down-line"></i>
                                            <i v-else class="fas fa-sort"></i>
                                        </span>
                                    </th>
                                    <th @click="sort('pf_number')">
                                        PF Number
                                        <span>
                                            <i v-if="meta.keyword == 'pf_number' && meta.order_by == 'asc'"
                                                class="ri-arrow-up-line"></i>
                                            <i v-else-if="meta.keyword == 'pf_number' && meta.order_by == 'desc'"
                                                class="ri-arrow-down-line"></i>
                                            <i v-else class="fas fa-sort"></i>
                                        </span>
                                    </th>
                                    <th @click="sort('police_station_id')" width="870%">
                                        Police Station
                                        <span>
                                            <i v-if="meta.keyword == 'police_station_id' && meta.order_by == 'asc'"
                                                class="ri-arrow-up-line"></i>
                                            <i v-else-if="meta.keyword == 'police_station_id' && meta.order_by == 'desc'"
                                                class="ri-arrow-down-line"></i>
                                            <i v-else class="fas fa-sort"></i>
                                        </span>
                                    </th>
                                    <th @click="sort('crime_no_sec')" width="870%">
                                        Crime No
                                        <span>
                                            <i v-if="meta.keyword == 'crime_no_sec' && meta.order_by == 'asc'"
                                                class="ri-arrow-up-line"></i>
                                            <i v-else-if="meta.keyword == 'crime_no_sec' && meta.order_by == 'desc'"
                                                class="ri-arrow-down-line"></i>
                                            <i v-else class="fas fa-sort"></i>
                                        </span>
                                    </th>
                                    <th @click="sort('section_of_law')" width="870%">
                                        Section of Law
                                        <span>
                                            <i v-if="meta.keyword == 'section_of_law' && meta.order_by == 'asc'"
                                                class="ri-arrow-up-line"></i>
                                            <i v-else-if="meta.keyword == 'section_of_law' && meta.order_by == 'desc'"
                                                class="ri-arrow-down-line"></i>
                                            <i v-else class="fas fa-sort"></i>
                                        </span>
                                    </th>
                                    <th @click="sort('investigation_officer')">
                                        Investigation Officer
                                        <span>
                                            <i v-if="meta.keyword == 'investigation_officer' && meta.order_by == 'asc'"
                                                class="ri-arrow-up-line"></i>
                                            <i v-else-if="meta.keyword == 'investigation_officer' && meta.order_by == 'desc'"
                                                class="ri-arrow-down-line"></i>
                                            <i v-else class="fas fa-sort"></i>
                                        </span>
                                    </th>
                                    <th @click="sort('holding_officer')">
                                        Holding Officer
                                        <span>
                                            <i v-if="meta.keyword == 'holding_officer' && meta.order_by == 'asc'"
                                                class="ri-arrow-up-line"></i>
                                            <i v-else-if="meta.keyword == 'holding_officer' && meta.order_by == 'desc'"
                                                class="ri-arrow-down-line"></i>
                                            <i v-else class="fas fa-sort"></i>
                                        </span>
                                    </th>
                                    <th @click="sort('siezed_from_whom')">
                                        Seized From Whom
                                        <span>
                                            <i v-if="meta.keyword == 'siezed_from_whom' && meta.order_by == 'asc'"
                                                class="ri-arrow-up-line"></i>
                                            <i v-else-if="meta.keyword == 'siezed_from_whom' && meta.order_by == 'desc'"
                                                class="ri-arrow-down-line"></i>
                                            <i v-else class="fas fa-sort"></i>
                                        </span>
                                    </th>
                                    <th @click="sort('siezed_from_whom')" class="text-nowrap">
                                        Seized Property Details
                                        <span>
                                            <i v-if="meta.keyword == 'siezed_from_whom' && meta.order_by == 'asc'"
                                                class="ri-arrow-up-line"></i>
                                            <i v-else-if="meta.keyword == 'siezed_from_whom' && meta.order_by == 'desc'"
                                                class="ri-arrow-down-line"></i>
                                            <i v-else class="fas fa-sort"></i>
                                        </span>
                                    </th>
                                    <th>Siezed Property Available</th>
                                    <th>Disposed</th>
                                    <th>Property Disposed Reason</th>
                                    <th @click="sort('pr_no')">
                                        PR No.
                                        <span>
                                            <i v-if="meta.keyword == 'pr_no' && meta.order_by == 'asc'"
                                                class="ri-arrow-up-line"></i>
                                            <i v-else-if="meta.keyword == 'pr_no' && meta.order_by == 'desc'"
                                                class="ri-arrow-down-line"></i>
                                            <i v-else class="fas fa-sort"></i>
                                        </span>
                                    </th>
                                    <th @click="sort('pr_date')">
                                        PR Date
                                        <span>
                                            <i v-if="meta.keyword == 'pr_date' && meta.order_by == 'asc'"
                                                class="ri-arrow-up-line"></i>
                                            <i v-else-if="meta.keyword == 'pr_date' && meta.order_by == 'desc'"
                                                class="ri-arrow-down-line"></i>
                                            <i v-else class="fas fa-sort"></i>
                                        </span>
                                    </th>
                                    <th @click="sort('cc_sc_no')">
                                        CC No/ SC No
                                        <span>
                                            <i v-if="meta.keyword == 'cc_sc_no' && meta.order_by == 'asc'"
                                                class="ri-arrow-up-line"></i>
                                            <i v-else-if="meta.keyword == 'cc_sc_no' && meta.order_by == 'desc'"
                                                class="ri-arrow-down-line"></i>
                                            <i v-else class="fas fa-sort"></i>
                                        </span>
                                    </th>
                                    <th>Present Stage of the Case</th>

                                    <th @click="sort('division_id')">
                                        Division
                                        <span>
                                            <i v-if="meta.keyword == 'division_id' && meta.order_by == 'asc'"
                                                class="ri-arrow-up-line"></i>
                                            <i v-else-if="meta.keyword == 'division_id' && meta.order_by == 'desc'"
                                                class="ri-arrow-down-line"></i>
                                            <i v-else class="fas fa-sort"></i>
                                        </span>
                                    </th>
                                    <th @click="sort('sub_division_id')">
                                        Sub Division
                                        <span>
                                            <i v-if="meta.keyword == 'sub_division_id' && meta.order_by == 'asc'"
                                                class="ri-arrow-up-line"></i>
                                            <i v-else-if="meta.keyword == 'sub_division_id' && meta.order_by == 'desc'"
                                                class="ri-arrow-down-line"></i>
                                            <i v-else class="fas fa-sort"></i>
                                        </span>
                                    </th>
                                    <th class="text-center">Actions</th>
                                </tr>
                            </thead>
                            <tbody class="bg1" v-for="muddemaal, key in muddemaals" :key="key"
                                id="tooltip-button-interactive">
                                <tr v-if="muddemaals.length == 0">
                                    <td colspan="14" class="text-center">No records found</td>
                                </tr>
                                <tr>
                                    <td :rowspan="muddemaal.rowspan" class="text-center text-nowrap">
                                        {{ meta.from + key }}
                                        <a href="javascript:void(0)" class="text-primary me-2" @click="edit(muddemaal)"
                                            title="Edit"><i class="ri-edit-line fs-18 lh-1"></i></a>
                                    </td>
                                    <td :rowspan="muddemaal.rowspan" class="text-nowrap"
                                        @click="viewMuddemal(muddemaal)">
                                        <Highlighter class="my-highlight" highlightClassName="highlight"
                                            :searchWords="keywords" :autoEscape="true"
                                            :textToHighlight="muddemaal.year" />
                                    </td>
                                    <td :rowspan="muddemaal.rowspan" class="text-nowrap"
                                        @click="viewMuddemal(muddemaal)">
                                        <Highlighter class="my-highlight" highlightClassName="highlight"
                                            :searchWords="keywords" :autoEscape="true"
                                            :textToHighlight="muddemaal.pf_date" />
                                    </td>
                                    <td :rowspan="muddemaal.rowspan" @click="viewMuddemal(muddemaal)">
                                        <Highlighter class="my-highlight" highlightClassName="highlight"
                                            :searchWords="keywords" :autoEscape="true"
                                            :textToHighlight="muddemaal.pf_number" />
                                    </td>
                                    <td :rowspan="muddemaal.rowspan" @click="viewMuddemal(muddemaal)">
                                        <Highlighter class="my-highlight" highlightClassName="highlight"
                                            :searchWords="keywords" :autoEscape="true"
                                            :textToHighlight="muddemaal.police_Station?.police_station" />
                                    </td>
                                    <td :rowspan="muddemaal.rowspan" @click="viewMuddemal(muddemaal)">
                                        <Highlighter class="my-highlight" highlightClassName="highlight"
                                            :searchWords="keywords" :autoEscape="true"
                                            :textToHighlight="muddemaal.crime_no_sec" />
                                    </td>
                                    <td :rowspan="muddemaal.rowspan" @click="viewMuddemal(muddemaal)">
                                        <Highlighter class="my-highlight" highlightClassName="highlight"
                                            :searchWords="keywords" :autoEscape="true"
                                            :textToHighlight="muddemaal.section_of_law" />
                                    </td>
                                    <td :rowspan="muddemaal.rowspan" @click="viewMuddemal(muddemaal)">
                                        <Highlighter class="my-highlight" highlightClassName="highlight"
                                            :searchWords="keywords" :autoEscape="true"
                                            :textToHighlight="muddemaal.investigation_officer" />
                                    </td>
                                    <td :rowspan="muddemaal.rowspan" @click="viewMuddemal(muddemaal)">
                                        <Highlighter class="my-highlight" highlightClassName="highlight"
                                            :searchWords="keywords" :autoEscape="true"
                                            :textToHighlight="muddemaal.holding_officer" />
                                    </td>
                                    <td :rowspan="muddemaal.rowspan" @click="viewMuddemal(muddemaal)">
                                        <Highlighter class="my-highlight" highlightClassName="highlight"
                                            :searchWords="keywords" :autoEscape="true"
                                            :textToHighlight="muddemaal.siezed_from_whom" />
                                    </td>
                                    <td
                                        v-if="muddemaal.properties.length && muddemaal?.properties[0]?.property_description">
                                        <Highlighter class="my-highlight" highlightClassName="highlight"
                                            :searchWords="keywords" :autoEscape="true"
                                            :textToHighlight="'1. ' + muddemaal?.properties[0]?.property_description" />
                                    </td>
                                    <td v-else></td>
                                    <td class="text-center" v-if="muddemaal.properties.length">
                                        <span v-if="muddemaal?.properties[0]?.siezed_property_available == 1"
                                            type="button" class="btn badge badge-pill bg-success" data-bs-toggle="modal"
                                            data-bs-target="#SeizedPropertyAvailable"
                                            @click="getPropertyDetail(muddemaal?.properties[0])">
                                            {{ muddemaal?.properties[0]?.siezed_property_available == 1 ? 'Yes' : 'No'
                                            }}
                                        </span>
                                        <span v-else-if="muddemaal?.properties[0]?.siezed_property_available == null"
                                            type="button" class="btn badge badge-pill bg-secondary"
                                            data-bs-toggle="modal" data-bs-target="#SeizedPropertyAvailable"
                                            @click="getPropertyDetail(muddemaal?.properties[0])">
                                            Add
                                        </span>
                                        <span v-else type="button" class="btn badge badge-pill bg-danger"
                                            data-bs-toggle="modal" data-bs-target="#SeizedPropertyAvailable"
                                            @click="getPropertyDetail(muddemaal?.properties[0])">
                                            {{ muddemaal?.properties[0]?.siezed_property_available == 1 ? 'Yes' : 'No'
                                            }}
                                        </span>
                                    </td>
                                    <td v-else></td>
                                    <td class="text-center" v-if="muddemaal.properties.length">
                                        <span v-if="muddemaal?.properties[0]?.disposed == 1" type="button"
                                            class="btn badge badge-pill bg-success" data-bs-toggle="modal"
                                            data-bs-target="#propertyDetail"
                                            @click="getPropertyDisposal(muddemaal?.properties[0])">
                                            {{ muddemaal?.properties[0]?.disposed == 1 ? 'Yes' : 'No' }}
                                        </span>
                                        <span v-else type="button" class="btn badge badge-pill bg-danger"
                                            data-bs-toggle="modal" data-bs-target="#propertyDetail"
                                            @click="getPropertyDisposal(muddemaal?.properties[0])">
                                            {{ muddemaal?.properties[0]?.disposed == 0 ? 'No' : 'Yes' }}
                                        </span>
                                    </td>

                                    <td class="text-center" v-else></td>

                                    <td
                                        v-if="muddemaal.properties.length && muddemaal?.properties[0].property_disposal_stage">
                                        <Highlighter class="my-highlight" highlightClassName="highlight"
                                            :searchWords="keywords" :autoEscape="true"
                                            :textToHighlight="muddemaal?.properties[0]?.property_disposal_stage?.property_disposal_stage" />
                                    </td>
                                    <td class="text-center" v-else></td>
                                    <td :rowspan="muddemaal.rowspan">
                                        <Highlighter class="my-highlight" highlightClassName="highlight"
                                            :searchWords="keywords" :autoEscape="true"
                                            :textToHighlight="muddemaal.pr_no" />
                                    </td>
                                    <td :rowspan="muddemaal.rowspan">
                                        <Highlighter class="my-highlight" highlightClassName="highlight"
                                            :searchWords="keywords" :autoEscape="true"
                                            :textToHighlight="muddemaal.pr_date" />
                                    </td>
                                    <td :rowspan="muddemaal.rowspan">
                                        <Highlighter class="my-highlight" highlightClassName="highlight"
                                            :searchWords="keywords" :autoEscape="true"
                                            :textToHighlight="muddemaal.cc_sc_no" />
                                    </td>
                                    <td :rowspan="muddemaal.rowspan" class="text-center">
                                        <span v-if="muddemaal.case_stage_id" type="button"
                                            class="btn badge badge-pill bg-primary" data-bs-toggle="modal"
                                            data-bs-target="#exampleModal" @click="getCaseStage(muddemaal)">
                                            {{ muddemaal.case_stage?.case_stage }}
                                        </span>
                                        <span v-else type="button" class="btn badge badge-pill bg-secondary"
                                            data-bs-toggle="modal" data-bs-target="#exampleModal"
                                            @click="getCaseStage(muddemaal)">Add </span>
                                    </td>

                                    <td :rowspan="muddemaal.rowspan">
                                        <Highlighter class="my-highlight" highlightClassName="highlight"
                                            :searchWords="keywords" :autoEscape="true"
                                            :textToHighlight="muddemaal.division?.division" />
                                    </td>
                                    <td :rowspan="muddemaal.rowspan">
                                        <Highlighter class="my-highlight" highlightClassName="highlight"
                                            :searchWords="keywords" :autoEscape="true"
                                            :textToHighlight="muddemaal.sub_division?.sub_division" />
                                    </td>
                                    <td :rowspan="muddemaal.rowspan" class="text-center">
                                        <nav>
                                            <a href="javascript:void(0)" class="text-primary me-2"
                                                @click="edit(muddemaal)" title="Edit"><i
                                                    class="ri-edit-line fs-18 lh-1"></i></a>
                                            <a href="javascript:void(0)" class="text-danger me-2"
                                                v-if="$store.getters.user.is_admin" @click="deleteMuddemal(muddemaal)"
                                                title="Delete"><i class="ri-delete-bin-6-line fs-18 lh-1"></i></a>
                                        </nav>
                                    </td>
                                </tr>
                                <tr v-for="property, key in muddemaal.properties.slice(1)" :key="key">
                                    <td class="hover1">
                                        <Highlighter class="my-highlight" highlightClassName="highlight"
                                            :searchWords="keywords" :autoEscape="true"
                                            :textToHighlight="'   ' + (key + 2) + '. ' + property?.property_description" />
                                    </td>
                                    <td class="text-center">
                                        <span v-if="property.siezed_property_available == 1" type="button"
                                            class="btn badge badge-pill bg-success" data-bs-toggle="modal"
                                            data-bs-target="#SeizedPropertyAvailable"
                                            @click="getPropertyDetail(property)">
                                            {{ property.siezed_property_available == 1 ? 'Yes' : 'No' }}
                                        </span>
                                        <span v-else type="button" class="btn badge badge-pill bg-danger"
                                            data-bs-toggle="modal" data-bs-target="#SeizedPropertyAvailable"
                                            @click="getPropertyDetail(property)">
                                            {{ property.siezed_property_available == 1 ? 'Yes' : 'No' }}
                                        </span>
                                    </td>
                                    <td class="text-center">
                                        <span v-if="property.disposed == 1" type="button"
                                            class="btn badge badge-pill bg-success" data-bs-toggle="modal"
                                            data-bs-target="#propertyDetail" @click="getPropertyDisposal(property)">
                                            {{ property.disposed == 1 ? 'Yes' : 'No' }}
                                        </span>
                                        <span v-else type="button" class="btn badge badge-pill bg-danger"
                                            data-bs-toggle="modal" data-bs-target="#propertyDetail"
                                            @click="getPropertyDisposal(property)">
                                            {{ property.disposed == 0 ? 'No' : 'Yes' }}
                                        </span>
                                    </td>
                                    <td class="hover1">
                                        {{ property?.property_disposal_stage?.property_disposal_stage }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="card-footer">
                    <Pagination :maxPage="meta.maxPage" :totalPages="meta.lastPage" :currentPage="meta.page"
                        @pagechanged="onPageChange" />
                </div>
            </div>
        </div>
    </div>

    <!-- Modal -->
    <div class="modal modal-lg fade" id="propertyDetail" tabindex="-1" aria-labelledby="exampleModalLabel"
        aria-hidden="true" ref="disposalModal">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Property Disposal</h5>
                    <button type="button" id="close" class="btn-close" data-bs-dismiss="modal"
                        aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="row mb-3">
                        <div class="col-md-4 mb-2">
                            <div class="d-flex justify-content-between">
                                <label class="form-label">Disposed</label>
                                <i @click="getPropertyFieldHistory('disposed')"
                                    class="ri-history-line fs-18  text-primary"></i>
                            </div>
                            <select class="form-control" v-model="property_disposal.disposed" @change="setDisposal()">
                                <option value="">Select Disposed</option>
                                <option value="1">Yes</option>
                                <option value="0">No</option>
                            </select>
                        </div>
                        <div class="col-md-4 mb-2">
                            <div class="d-flex justify-content-between">
                                <label class="form-label">Disposed Date <span class="text-danger"> *</span></label>
                                <i @click="getPropertyFieldHistory('disposed_date')"
                                    class="ri-history-line fs-18  text-primary"></i>
                            </div>
                            <input type="date" class="form-control" v-model="property_disposal.disposed_date"
                                :class="{ 'is-invalid': errors?.disposed_date }" />
                            <span v-if="errors?.disposed_date" class="invalid-feedback">{{ errors?.disposed_date[0]
                                }}</span>
                        </div>
                        <div class="col-md-4 mb-2">
                            <div class="d-flex justify-content-between">
                                <label class="form-label">Property Disposal Reason</label>
                                <i @click="getPropertyFieldHistory('property_disposal_stage_id')"
                                    class="ri-history-line fs-18  text-primary"></i>
                            </div>
                            <select class="form-control" v-model="property_disposal.property_disposal_stage_id"
                                @change="getDisposedStatus('property_disposed', property_disposal.property_disposal_stage_id)"
                                :class="{ 'is-invalid': errors?.property_disposal_stage_id }">
                                <option value="null">Select Property Disposal</option>
                                <option v-for="property_disposal, key in disposal_stages" :key="key"
                                    :value="property_disposal.property_disposal_stage_id"> {{
                                        property_disposal.property_disposal_stage }}</option>
                            </select>
                            <span v-if="errors?.property_disposal_stage_id" class="invalid-feedback">{{
                                errors?.property_disposal_stage_id[0]
                                }}.</span>
                        </div>
                        <div class="col-md-6 mb-2">
                            <div class="d-flex justify-content-between">
                                <label class="form-label">Incharge Officer</label>
                                <i @click="getPropertyFieldHistory('officer_incharge')"
                                    class="ri-history-line fs-18  text-primary"></i>
                            </div>
                            <input type="text" class="form-control" placeholder="Incharge Officer"
                                v-model="property_disposal.officer_incharge"
                                :class="{ 'is-invalid': errors?.officer_incharge }" />
                            <span v-if="errors?.officer_incharge" class="invalid-feedback">{{
                                errors?.officer_incharge[0].slice(0, -5)
                                }}.</span>
                        </div>
                        <div class="col-md-6 mb-2">
                            <div class="d-flex justify-content-between">
                                <label class="form-label">If Any Remarks By Incharge Officer </label>
                                <i @click="getPropertyFieldHistory('instructions_by_officer')"
                                    class="ri-history-line fs-18  text-primary"></i>
                            </div>

                            <input type="text" class="form-control" placeholder="Remarks By Incharge Officer"
                                v-model="property_disposal.instructions_by_officer" />
                        </div>
                        <!-- <div v-if="property_disposed" class="col-md-4 mb-2">
                            <label class="form-label">Auction / Sold</label>
                            <select class="form-control" v-model="property_disposal.auction_sold" :class="{'is-invalid': errors?.auction_sold}">
                                <option value="null">Select Auction / Sold</option>
                                <option value="Auction">Auction</option>
                                <option value="Sold">Sold</option>
                            </select>
                            <span v-if="errors?.auction_sold" class="invalid-feedback">{{ errors?.auction_sold[0] }}</span>
                        </div> -->
                        <div v-if="property_disposed" class="col-md-6 mb-2">
                            <div class="d-flex justify-content-between">
                                <label class="form-label">Auction Value</label>
                                <i @click="getPropertyFieldHistory('auction_sold_value')"
                                    class="ri-history-line fs-18  text-primary"></i>
                            </div>

                            <input type="text" class="form-control" placeholder="Auction Value"
                                :class="{ 'is-invalid': errors?.auction_sold_value }"
                                v-model="property_disposal.auction_sold_value" />
                            <span v-if="errors?.auction_sold_value" class="invalid-feedback">{{
                                errors?.auction_sold_value[0] }}</span>
                        </div>
                        <div v-if="property_disposed" class="col-md-6 mb-2">
                            <div class="d-flex justify-content-between">
                                <label class="form-label">Auction Date</label>
                                <i @click="getPropertyFieldHistory('auction_sold_date')"
                                    class="ri-history-line fs-18  text-primary"></i>
                            </div>

                            <input type="date" class="form-control" :class="{ 'is-invalid': errors?.auction_sold_date }"
                                v-model="property_disposal.auction_sold_date" />
                            <span v-if="errors?.auction_sold_date" class="invalid-feedback">{{
                                errors?.auction_sold_date[0] }}</span>
                        </div>
                    </div>
                    <div v-if="disposal_history_status" class="row">
                        <div class="col-md-12 mb-2">
                            <div class="table-responsive mb-2">
                                <!-- <input type="email" class="form-control mb-2" id="exampleFormControlInput1" placeholder="Search"
                            v-model="police_Station_meta.search" @keypress.enter="modelsearch()" /> -->
                                <table class="table table-striped text-nowrap table-bottom-border mb-0">
                                    <thead>
                                        <tr style="background-color: #f5f5f5;">
                                            <th class="text-center">Sl No.</th>
                                            <th>Updated Value</th>
                                            <th>Old Value</th>
                                            <th>Edited User</th>
                                            <th>Date & Time</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="value, key in field_history" :key="key">
                                            <td class="text-center">{{ history_meta.from + key }}</td>
                                            <td> {{ value.new_value }} </td>
                                            <td> {{ value.old_value }} </td>
                                            <td> {{ value.edited_by?.name }} </td>
                                            <td> {{ changeDateFormat(value.edited_date_time) }} </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <!-- <div class="card-footer">
                                <Pagination :maxPage="police_Station_meta.maxPage" :totalPages="police_Station_meta.lastPage" :currentPage="police_Station_meta.page" @pagechanged="onmodelPageChange" />
                            </div> -->
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    <button type="button" class="btn btn-primary" @click="updateDisposal()">Submit</button>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Present Stage of the Cases</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <select class="form-control" v-model="case_stage.case_stage_id">
                        <option value="">Select Present Stage of the Case</option>
                        <option v-for="case_stage, key in case_stages" :key="key" :value="case_stage.case_stage_id">
                            {{ case_stage.case_stage }}</option>
                    </select>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    <button type="button" class="btn btn-primary" data-bs-dismiss="modal"
                        @click="updateCaseStage()">Submit</button>
                </div>
            </div>
        </div>
    </div>
    <div class="modal modal-lg fade" id="SeizedPropertyAvailable" tabindex="-1" aria-labelledby="exampleModalLabel"
        aria-hidden="true" ref="SeizedPropertyModal">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Seized Property Available</h5>
                    <button type="button" id="close1" class="btn-close" data-bs-dismiss="modal"
                        aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="row mb-3">
                        <div class="col-md-6 mb-2">
                            <label class="form-label">Seized Property Available &nbsp;</label>
                            <i @click="getPropertyFieldHistory('siezed_property_available')"
                                class="ri-history-line fs-18  text-primary"></i>

                            <select class="form-control" v-model="siezed_property_available.siezed_property_available"
                                @change="getvalue(siezed_property_available)">
                                <option value="null">Select Seized Property Available</option>
                                <option value="1">Yes</option>
                                <option value="0">No</option>
                            </select>
                        </div>
                        <div class="col-md-6 mb-2">
                            <label class="form-label">Remarks &nbsp;</label>
                            <i @click="getPropertyFieldHistory('remarks_if_property_not_available')"
                                class="ri-history-line fs-18  text-primary"></i>
                            <input type="text" class="form-control" placeholder="Remarks"
                                :class="{ 'is-invalid': errors?.remarks_if_property_not_available }"
                                v-model="siezed_property_available.remarks_if_property_not_available"
                                :disabled="siezed_property_available.siezed_property_available == '1' || siezed_property_available.siezed_property_available == null" />
                            <span v-if="errors?.remarks_if_property_not_available" class="invalid-feedback">{{
                                errors?.remarks_if_property_not_available[0] }}</span>
                        </div>
                    </div>
                    <div v-if="history_status" class="row">
                        <div class="col-md-12 mb-2">
                            <div class="table-responsive mb-2">
                                <!-- <input type="email" class="form-control mb-2" id="exampleFormControlInput1" placeholder="Search"
                            v-model="police_Station_meta.search" @keypress.enter="modelsearch()" /> -->
                                <table class="table table-striped text-nowrap table-bottom-border mb-0">
                                    <thead>
                                        <tr style="background-color: #f5f5f5;">
                                            <th class="text-center">Sl No.</th>
                                            <th>Updated Value</th>
                                            <th>Old Value</th>
                                            <th>Edited User</th>
                                            <th>Date & Time</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="value, key in field_history" :key="key">
                                            <td class="text-center">{{ history_meta.from + key }}</td>
                                            <td> {{ value.new_value }} </td>
                                            <td> {{ value.old_value }} </td>
                                            <td> {{ value.edited_by?.name }} </td>
                                            <td> {{ changeDateFormat(value.edited_date_time) }} </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="card-footer">
                                <Pagination :maxPage="history_meta.maxPage" :totalPages="history_meta.lastPage"
                                    :currentPage="history_meta.page" @pagechanged="historyPageChange" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    <button type="button" class="btn btn-primary" @click="updateProperty()">Submit</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Highlighter from "vue-highlight-words";
import MultiSelect from "primevue/multiselect";
import Pagination from "@/components/Pagination.vue";
import Search from "@/components/Search.vue";
import moment from "moment";
export default {
    components: {
        Pagination,
        Search,
        MultiSelect,
        Highlighter,
    },
    data() {
        return {
            show: false,
            meta: {
                search: "",
                order_by: "asc",
                keyword: "muddemal_id",
                per_page: 10,
                totalRows: 0,
                page: 1,
                lastPage: 1,
                from: 1,
                maxPage: 1,
                police_station_id: [],
                year: [],
                division_id: '',
                sub_division_id: '',
                disposed: '',
                is_available: '',
                is_valuable: '',
                case_stage_id: '',
                property_disposal_stage_id: ''
            },
            police_stations: [],
            options: [],
            muddemaals: [],
            currentYear: new Date().getFullYear(),
            years: [],
            case_stages: [],
            disposal_stages: [],
            errors: [],
            police_station_name: "",
            selected_year: "",
            case_stage: {
                muddemal_id: "",
                case_stage_id: "",
                case_stage_name: "",
            },
            property_disposal: {
                property_id: "",
                property_disposal_id: "",
                disposed: "",
                disposed_date: "",
                property_disposal_stage_id: "",
                officer_incharge: "",
                instructions_by_officer: "",
                auction_sold: "",
                auction_sold_value: "",
                auction_sold_date: "",
            },
            siezed_property_available: {
                property_id: "",
                muddemal_id: "",
                property_detail_id: "",
                siezed_property_available: null,
                remarks_if_property_not_available: "",
            },
            property_disposed: false,
            selected_police_stations: [],
            selected_years: [],
            field_history: [],
            history_status: false,
            disposal_history_status: false,
            property_id: "",
            muddemal_id: "",
            history_meta: {
                search: "",
                order_by: "asc",
                keyword: "old_value",
                per_page: 10,
                totalRows: 0,
                page: 1,
                lastPage: 1,
                from: 1,
                maxPage: 1,
                property_id: [],
                field: '',
                muddemal_id: "",
            },
            division_id: '',
            sub_division_id: '',
            divisions: [],
            sub_divisions: [],
            current_disposal: {
                disposed: '',
                disposed_date: '',
                property_disposal_stage_id: '',
                officer_incharge: '',
                instructions_by_officer: '',
                auction_sold_value: '',
                auction_sold_date: '',
                initialized: false
            }
        };
    },
    computed: {
        keywords() {
            return this.meta.search.split(" ");
        },
    },
    beforeRouteEnter(to, from, next) {
        next((vm) => {
            if (from.name == "Login") {
                vm.$router.go();
            }
            if (from.name != "MuddemalView" && from.name != "MuddemaalCreate" && from.name != "MuddemaalEdit") {
                vm.$store.commit("setCurrentPage", vm.meta.page);
                vm.$store.commit("setSearchKeyword", vm.meta.search);
                vm.$store.commit("setPoliceStationIds", vm.meta.police_station_id);
                vm.$store.commit("setYears", vm.meta.year);
            } else {
                vm.meta.page = vm.$store.getters.current_page;
                vm.meta.search = vm.$store.getters.search_keyword;
                vm.meta.police_station_id = vm.$store.getters.police_station_ids;
                vm.meta.police_station_id = vm.$store.getters.police_station_ids;
                vm.meta.year = vm.$store.getters.years;
            }
            vm.getDivisions();
            vm.getFilteredPoliceStations();
            vm.getYears();
            vm.getCaseStages();
            vm.index();
        });
    },
    mounted() {
        // let vm = this;
        // vm.getPoliceStations();
        // vm.index();
        // vm.getYears();
    },
    watch: {
        'meta.division_id': function (val) {
            this.getFilteredPoliceStations();
        },
        'meta.sub_division_id': function (val) {
            this.getFilteredPoliceStations();
        }
    },
    methods: {
        changeDateFormat(date) {
            if (date) {
                return moment(date).format('YYYY-MM-DD HH:mm')
                // console.log("date", moment(date))
                // console.log("date", moment(date, 'YYYY-MM-DD HH:mm:ss').format("yyyy-MM-DD HH:mm"))
            }
        },
        getPropertyFieldHistory(field) {
            let vm = this;
            vm.history_meta.property_id = vm.property_id
            vm.history_meta.field = field
            vm.history_meta.muddemal_id = vm.muddemal_id
            if (vm.siezed_property_available.property_id) {
                vm.history_status = true;
                vm.disposal_history_status = false
            }
            if (vm.property_disposal.property_id) {
                vm.history_status = false;
                vm.disposal_history_status = true
            }
            // let data = {
            //     "property_id":vm.siezed_property_available.property_id,
            //     "field":field,
            //     "muddemal_id":vm.siezed_property_available.muddemal_id
            // }
            vm.$store
                .dispatch("post", { uri: "getPropertyEditHistory", data: vm.history_meta })
                .then(function (response) {
                    vm.field_history = response.data.data
                    vm.history_meta.totalRows = response.data.meta.total;
                    vm.history_meta.lastPage = response.data.meta.last_page;
                    vm.history_meta.from = response.data.meta.from;
                    vm.history_meta.maxPage = vm.history_meta.lastPage >= 3 ? 3 : vm.history_meta.lastPage;
                    // vm.case_stages = response.data.data;
                    // vm.getPoliceStations();
                })
                .catch(function (error) {
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
        },
        getvalue() {
            let vm = this;
            if (vm.siezed_property_available.siezed_property_available == '1') {
                vm.siezed_property_available.remarks_if_property_not_available = ""
            }
        },
        showDropdown() {
            this.show = !this.show;
        },

        getDisposedStatus(key, value) {
            let vm = this;
            if (key == "property_disposed" && value == true) {
                vm.property_disposed = true;
            } else {
                vm.property_disposed = false;
            }
        },

        index() {
            let vm = this;
            let loader = vm.$loading.show();
            vm.$store
                .dispatch("post", { uri: "paginateMuddemals", data: vm.meta })
                .then(function (response) {
                    loader.hide();
                    vm.muddemaals = response.data.data;
                    vm.meta.totalRows = response.data.meta.total;
                    vm.meta.lastPage = response.data.meta.last_page;
                    vm.meta.from = response.data.meta.from;
                    vm.meta.maxPage = vm.meta.lastPage >= 3 ? 3 : vm.meta.lastPage;
                    vm.muddemaals.map(function (ele) {
                        if (ele.properties?.length) {
                            ele.rowspan = ele.properties.length;
                        }
                    });
                })
                .catch(function (error) {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
        },

        updateDisposal() {
            let vm = this;
            let loader = vm.$loading.show();
            vm.$store
                .dispatch("post", { uri: "updateDisposal", data: vm.property_disposal })
                .then(function (response) {
                    loader.hide();
                    for (const key in vm.current_disposal) { vm.current_disposal[key] = null };
                    vm.$store.dispatch("success", response.data.message);
                    vm.index();
                    document.getElementById("close").click();
                })
                .catch(function (error) {
                    loader.hide();
                    vm.errors = error.response?.data?.errors;
                    vm.$store.dispatch("error", error.response.data?.message);
                });
        },

        setDisposal() {
            if (this.property_disposal.disposed == '0') {
                if (!this.current_disposal.initialized) {
                    this.current_disposal.initialized = true;
                    this.current_disposal.disposed = this.property_disposal.disposed;
                    this.current_disposal.disposed_date = this.property_disposal.disposed_date;
                    this.current_disposal.property_disposal_stage_id = this.property_disposal.property_disposal_stage_id;
                    this.current_disposal.officer_incharge = this.property_disposal.officer_incharge;
                    this.current_disposal.instructions_by_officer = this.property_disposal.instructions_by_officer;
                    this.current_disposal.auction_sold_value = this.property_disposal.auction_sold_value;
                    this.current_disposal.auction_sold_date = this.property_disposal.auction_sold_date;
                }
                for (const key in this.current_disposal) {
                    if (key != 'initialized' && key != 'disposed') {
                        this.property_disposal[key] = null;
                    }
                }
            } else {
                if (this.current_disposal.initialized) {
                    for (const key in this.current_disposal) {
                        if (key != 'disposed') {
                            this.property_disposal[key] = this.current_disposal[key];
                        }
                    }
                }
            }
        },

        getCaseStage(muddemal) {
            let vm = this;
            vm.case_stage.case_stage_id = "";
            if (muddemal.case_stage_id) {
                vm.$store
                    .dispatch("post", { uri: "getCaseStage", data: { case_stage_id: muddemal.case_stage_id } })
                    .then(function (response) {
                        vm.case_stage = response.data.data;
                        vm.case_stage.muddemal_id = muddemal.muddemal_id;
                    })
                    .catch(function (error) {
                        vm.errors = error.response?.data?.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            } else {
                vm.case_stage.muddemal_id = muddemal.muddemal_id;
            }
        },

        updateCaseStage() {
            let vm = this;
            let loader = vm.$loading.show();
            vm.$store
                .dispatch("post", { uri: "updateMuddemalCaseStage", data: vm.case_stage })
                .then(function (response) {
                    loader.hide();
                    vm.$store.dispatch("success", response.data.message);
                    vm.index();
                })
                .catch(function (error) {
                    loader.hide();
                    vm.errors = error.response?.data?.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
        },

        getPropertyDetail(property) {
            let vm = this;
            vm.field_history = [];
            vm.history_status = false;
            vm.siezed_property_available.siezed_property_available = null,
                vm.siezed_property_available.remarks_if_property_not_available = "",
                vm.errors = [];
            let loader = vm.$loading.show();
            vm.$store
                .dispatch("post", { uri: "getPropertyDetail", data: property })
                .then(function (response) {
                    loader.hide();
                    vm.siezed_property_available = response.data.data;
                    vm.property_id = vm.siezed_property_available.property_id
                    vm.muddemal_id = vm.siezed_property_available.muddemal_id
                })
                .catch(function (error) {
                    loader.hide();
                    vm.errors = error.response?.data?.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
        },

        updateProperty() {
            let vm = this;
            if (vm.siezed_property_available == "null") {
                vm.siezed_property_available == null
            }
            let loader = vm.$loading.show();

            vm.$store
                .dispatch("post", { uri: "updateSeizedProperty", data: vm.siezed_property_available })
                .then(function (response) {
                    loader.hide();
                    vm.$store.dispatch("success", response.data.message);
                    vm.index();

                    let modal = vm.$refs.SeizedPropertyModal;
                    modal.style.display = "none";
                    document.getElementById("close1").click();
                })
                .catch(function (error) {
                    loader.hide();
                    vm.errors = error.response?.data?.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
        },

        getDivisions() {
            let vm = this;
            let loader = vm.$loading.show();
            let uri = { uri: "getDivisions" };
            vm.$store.dispatch("post", uri)
                .then(function (response) {
                    loader.hide();
                    vm.divisions = response.data.data;
                })
                .catch(function (error) {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                })
        },

        obtainSubDivisions() {
            if (this.meta.division_id == '') {
                this.meta.sub_division_id = '';
                this.index();
                return;
            }
            this.meta.sub_division_id = '';
            this.selected_police_stations = [];
            let vm = this;
            let loader = vm.$loading.show();
            let uri = { uri: "obtainSubDivisions", data: { division_id: this.meta.division_id } };
            vm.$store.dispatch("post", uri)
                .then(function (response) {
                    loader.hide();
                    vm.sub_divisions = response.data.data;
                    vm.search();
                })
                .catch(function (error) {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
        },
        getPoliceStations() {
            let vm = this;
            let loader = vm.$loading.show();
            let uri = { uri: "getUserPoliceStations" };
            vm.$store
                .dispatch("post", uri)
                .then(function (response) {
                    loader.hide();
                    vm.police_stations = response.data.data;
                    if (vm.$store.getters.police_station_ids.length) {
                        vm.$store.getters.police_station_ids.filter(function (ele) {
                            vm.police_stations.map(function (police_station) {
                                if (police_station.police_station_id == ele) {
                                    vm.selected_police_stations.push(police_station);
                                }
                            });
                        });
                    }
                    vm.getCaseStages();
                })
                .catch(function (error) {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
        },
        getCaseStages() {
            let vm = this;
            let loader = vm.$loading.show();
            let uri = { uri: "getCaseStages" };
            vm.$store
                .dispatch("post", uri)
                .then(function (response) {
                    loader.hide();
                    vm.case_stages = response.data.data;
                    vm.getPropertyDisposalStages();
                })
                .catch(function (error) {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
        },
        getPropertyDisposalStages() {
            let vm = this;
            let loader = vm.$loading.show();
            let uri = { uri: "getPropertyDisposalStages" };
            vm.$store
                .dispatch("post", uri)
                .then(function (response) {
                    loader.hide();
                    vm.disposal_stages = response.data.data;
                })
                .catch(function (error) {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
        },
        getYears() {
            let vm = this;
            let uri = { uri: "getMuddemalYear" };
            vm.$store
                .dispatch("post", uri)
                .then(function (response) {
                    vm.years = response.data;
                    if (vm.$store.getters.years.length) {
                        vm.$store.getters.years.filter(function (ele) {
                            vm.years.map(function (year) {
                                if (year.year == ele) {
                                    vm.selected_years.push(year);
                                }
                            });
                        });
                    }
                })
                .catch(function (error) {
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
        },
        setPage() {
            this.$store.commit("setCurrentPage", this.meta.page);
            this.$store.commit("setSearchKeyword", this.meta.search);
            this.$store.commit("setPoliceStationIds", this.meta.police_station_id);
            this.$store.commit("setYears", this.meta.year);
        },
        edit(muddemal) {
            this.$store.commit("setCurrentPage", this.meta.page);
            this.$store.commit("setSearchKeyword", this.meta.search);
            this.$store.commit("setPoliceStationIds", this.meta.police_station_id);
            this.$store.commit("setYears", this.meta.year);
            this.$router.push("/muddemal/" + muddemal.muddemal_id + "/edit");
        },

        viewMuddemal(muddemal) {
            this.$store.commit("setCurrentPage", this.meta.page);
            this.$store.commit("setSearchKeyword", this.meta.search);
            this.$store.commit("setPoliceStationIds", this.meta.police_station_id);
            this.$store.commit("setYears", this.meta.year);
            this.$router.push("/muddemal/" + muddemal.muddemal_id + "/view");
        },

        deleteMuddemal(muddemal) {
            let vm = this;
            if (confirm("Do You want to Delete this Record?..")) {
                let uri = { uri: "deleteMuddemal", data: muddemal };
                vm.$store
                    .dispatch("post", uri)
                    .then(function (response) {
                        vm.$store.dispatch("success", response.data.message);
                        vm.index();
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            }
        },
        historyPageChange(page) {
            this.history_meta.page = page;
            this.getPropertyFieldHistory(this.history_meta.field);
        },
        onPageChange(page) {
            this.meta.page = page;
            this.index();
        },
        search() {
            let vm = this;
            vm.meta.page = 1;
            vm.index();
        },
        sort(field) {
            this.meta.keyword = field;
            this.meta.order_by = this.meta.order_by == "asc" ? "desc" : "asc";
            this.search();
        },
        PoliceStaionFilter(selected_police_stations) {
            let vm = this;
            let name = "";
            vm.meta.police_station_id = [];
            selected_police_stations.map(function (ele) {
                vm.meta.police_station_id.push(ele.police_station_id);
                if (name == "") {
                    name = ele.police_station;
                } else {
                    name = name + ", " + ele.police_station;
                }
            });
            vm.police_station_name = name;
            vm.search();
        },
        yearFilter(years) {
            let vm = this;
            vm.meta.year = [];
            let year = "";
            years.map(function (ele) {
                vm.meta.year.push(ele.year);
                if (year == "") {
                    year = ele.year;
                } else {
                    year = year + ", " + ele.year;
                }
            });
            vm.selected_year = year;
            vm.search();
        },
        getPropertyDisposal(property) {
            let vm = this;
            vm.disposal_history_status = false
            vm.property_id = property.property_id
            vm.muddemal_id = property.muddemal_id

            vm.errors = [];
            let loader = vm.$loading.show();
            let uri = { uri: "getProperty", data: property };
            vm.$store
                .dispatch("post", uri)
                .then(function (response) {
                    loader.hide();
                    vm.property_disposal = response.data.data;
                    if (vm.property_disposal?.property_disposal_stage?.property_disposal_stage == "Auction") {
                        vm.property_disposed = true;
                    } else {
                        vm.property_disposed = false;
                    }
                })
                .catch(function (error) {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
        },
        reset() {
            let vm = this;
            vm.meta.police_station_id = "";
            vm.meta.year = "";
            vm.meta.search = "";
            vm.selected_police_stations = [];
            vm.selected_years = [];
            vm.meta.division_id = "";
            vm.meta.sub_division_id = "";
            vm.meta.case_stage_id = "";
            vm.meta.disposed = "";
            vm.meta.is_available = "";
            vm.meta.is_valuable = "";
            vm.meta.property_disposal_stage_id = "";
            vm.search();
        },
        downloadMuddemals() {
            let vm = this;
            let loader = vm.$loading.show();
            let uri = { uri: "downloadMuddemals", data:vm.meta };
            vm.$store
                .dispatch("post", uri)
                .then(function (response) {
                    loader.hide();
                    vm.$store.dispatch("success", response.data.message);
                    vm.$router.push("/logs");
                })
                .catch(function (error) {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
        },

        getFilteredPoliceStations() {
            let data = {
                division_id: this.meta.division_id,
                sub_division_id: this.meta.sub_division_id
            }
            let vm = this;
            let loader = vm.$loading.show();
            let uri = { uri: "getFilteredPoliceStations", data: data };
            vm.$store.dispatch("post", uri)
                .then(function (response) {
                    loader.hide();
                    vm.police_stations = response.data.data;
                })
                .catch(function (error) {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
        },
    },
};
</script>
<style scoped>
.dropdown {
    position: relative;
    cursor: pointer;
}

.overselect {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

td {
    cursor: pointer;
}

/* th {
        white-space: wrap;
    } */
input:focus,
select:focus {
    outline: none;
    box-shadow: 0 0 0 0 transparent;
}

/* 
    ::-webkit-scrollbar{
		height: 4px;
        width: 5px;
        background: gray;
    }

    ::-webkit-scrollbar-track {
    	background: #f1f1f1; 
    }
    
    ::-webkit-scrollbar-thumb {
    	background: #506fd9; 
    }

    ::-webkit-scrollbar-thumb:hover {
    	background: #555; 
    }

    ::-webkit-scrollbar-thumb:horizontal{
        background: #506fd9;
        border-radius: 10px;
    }
	::-webkit-scrollbar-thumb:vertical{
        background: #506fd9;
        border-radius: 10px;
    } */

th {
    position: sticky !important;
    background-color: rgb(245, 245, 245) !important;
    outline-offset: -1px;
    top: -1px;
    left: 0;
    white-space: nowrap;
}

tbody:hover {
    box-shadow: inset 0 0 0 9999px rgba(243, 245, 249, 0.85);
}

.bg1:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.05);
}

.multiselect {
    position: relative;
}

.multiselect ul {
    border: 1px solid #ddd;
    border-top: 0;
    border-radius: 0 0 3px 3px;
    left: 0px;
    padding: 8px 8px;
    top: -0.1rem;
    width: 100%;
    list-style: none;
    max-height: 150px;
    overflow: auto;
    background: white;
}

ul {
    padding-left: 1rem !important;
}
</style>
