<template>
    <div class="d-md-flex align-items-center justify-content-between mb-2 mt-2">
        <div>
            <h4 class="main-title mb-0" v-if="status">
                <router-link to="/muddemal" type="button"><i
                        class="ri-arrow-left-line menu_icon text-dark fs-18 lh-1 me-2"></i></router-link>Create Muddemal
            </h4>
            <h4 class="main-title mb-0" v-else>
                <router-link to="/muddemal" type="button"><i
                        class="ri-arrow-left-line menu_icon text-dark fs-18 lh-1 me-2"></i></router-link> Update
                Muddemal
            </h4>
        </div>
        <div class="d-flex gap-2 mt-3 mt-md-0">
            <router-link to="/muddemal" type="button" class="btn btn-primary"><i
                    class="ri-menu-line fs-18 lh-1 me-1"></i>Muddemals</router-link>
        </div>
    </div>
    <div class="row g-3">
        <div class="col-md-12">
            <div class="card mb-4">
                <div class="card-body">
                    <div class="mb-2">
                        <h5 class="mb-0">Muddemal Input Details</h5>
                        <div class="row">
                            <div class="col-12">
                                <div class="row my-2 g-3">
                                    <div class="col-12 d-flex flex-row-reverse">
                                        <div class="d-flex align-items-center">
                                            <label class="form-check me-3">
                                                <input type="radio" class="form-check-input" value="CRIME_NO"
                                                    v-model="form.number_type">
                                                Crime No.
                                            </label>

                                            <label class="form-check me-3">
                                                <input type="radio" class="form-check-input" value="UDR_NO"
                                                    v-model="form.number_type">
                                                UDR No.
                                            </label>

                                            <label class="form-check me-3">
                                                <input type="radio" class="form-check-input" value="NCR_NO"
                                                    v-model="form.number_type">
                                                NCR No.
                                            </label>
                                            <label class="form-check">
                                                <input type="radio" class="form-check-input" value="C_MISC"
                                                    v-model="form.number_type">
                                                C Misc.
                                            </label>
                                        </div>
                                    </div>

                                    <div class="col-3 mb-3">
                                        <label class="form-label">PF Number</label><span class="text-danger"> *</span>
                                        <input type="text" class="form-control" placeholder="PF Number"
                                            :class="{ 'is-invalid': errors.pf_number }" v-model="form.pf_number"
                                            ref="pf_number" />
                                        <span v-if="errors.pf_number" class="invalid-feedback">{{ errors.pf_number[0]
                                            }}</span>
                                    </div>
                                    <div class="col-3 mb-3">
                                        <label class="form-label">PF Date</label><span class="text-danger"> *</span>
                                        <input type="date" class="form-control"
                                            :class="{ 'is-invalid': errors.pf_date }" v-model="form.pf_date"
                                            @change="getYearFromDate(form.pf_date)" max="9999-12-31" />
                                        <span v-if="errors.pf_date" class="invalid-feedback">{{ errors.pf_date[0]
                                            }}</span>
                                    </div>
                                    <div class="col-3 mb-3">
                                        <label class="form-label">PF Year</label><span class="text-danger"> *</span>
                                        <input readonly type="text" class="form-control" placeholder="PF Year"
                                            :class="{ 'is-invalid': errors.year }" v-model="form.year" />

                                        <span v-if="errors.year" class="invalid-feedback">{{ errors.year[0] }}</span>
                                    </div>
                                    <div class="col-3 mb-3">
                                        <label class="form-label">{{ number_type_label }}</label>
                                        <span class="text-danger"> *</span>
                                        <input type="text" class="form-control" :placeholder="number_type_label"
                                            :class="{ 'is-invalid': errors.crime_no_sec }"
                                            v-model="form.crime_no_sec" />
                                        <span v-if="errors.crime_no_sec" class="invalid-feedback">{{
                                            errors.crime_no_sec[0] }}</span>
                                    </div>
                                    <div class="col-3 mb-3">
                                        <label class="form-label">Section of Law (IPC/BNS)</label>
                                        <input type="text" class="form-control" placeholder="Section of Law"
                                            :class="{ 'is-invalid': errors.section_of_law }"
                                            v-model="form.section_of_law" />
                                        <span v-if="errors.section_of_law" class="invalid-feedback">{{
                                            errors.section_of_law[0] }}</span>
                                    </div>
                                    <div class="col-3 mb-3">
                                        <label class="form-label">Investigation Officer[Seized Officer]</label>
                                        <input type="text" class="form-control" placeholder="Investigation Officer"
                                            :class="{ 'is-invalid': errors.investigation_officer }"
                                            v-model="form.investigation_officer" />
                                        <span v-if="errors.investigation_officer" class="invalid-feedback">{{
                                            errors.investigation_officer[0] }}</span>
                                    </div>
                                    <div class="col-3 mb-3">
                                        <label class="form-label">Seized From Whom</label>
                                        <input type="text" class="form-control" placeholder="Seized From Whom"
                                            :class="{ 'is-invalid': errors.siezed_from_whom }"
                                            v-model="form.siezed_from_whom" />
                                        <span v-if="errors.siezed_from_whom" class="invalid-feedback">{{
                                            errors.siezed_from_whom[0] }}</span>
                                    </div>
                                    <div class="col-3 mb-3">
                                        <label class="form-label">Seized From Place</label>
                                        <input type="text" class="form-control" placeholder="Seized From Place"
                                            :class="{ 'is-invalid': errors.siezed_from_place }"
                                            v-model="form.siezed_from_place" />
                                        <span v-if="errors.siezed_from_place" class="invalid-feedback">{{
                                            errors.siezed_from_place[0] }}</span>
                                    </div>
                                    <div class="col-3 mb-3">
                                        <label class="form-label">CC No/ SC No</label>
                                        <input type="text" class="form-control" placeholder="CC No/ SC No"
                                            :class="{ 'is-invalid': errors.cc_sc_no }" v-model="form.cc_sc_no" />
                                        <span v-if="errors.cc_sc_no" class="invalid-feedback">{{ errors.cc_sc_no[0]
                                            }}</span>
                                    </div>
                                    <div class="col-3 mb-3">
                                        <label class="form-label">Present Stage of the Case</label>
                                        <!-- <select class="form-control" :class="{'is-invalid': errors.case_stage_id}" v-model="form.case_stage_id">
                                            <option value="">Select Case Stage</option>
                                            <option v-for="case_stage, key in case_stages" :key="key" :value="case_stage.case_stage_id"> {{ case_stage.case_stage }}</option>
                                        </select> -->
                                        <search :class="{ 'is-invalid': errors.case_stage_id }"
                                            :customClass="{ 'is-invalid': errors.case_stage_id }"
                                            :initialize="form.case_stage_id" id="case_stage_id" label="case_stage"
                                            placeholder="Select Case Stage" :data="case_stages"
                                            @input="case_stage => form.case_stage_id = case_stage">
                                        </search>
                                        <span v-if="errors.case_stage_id" class="invalid-feedback">{{
                                            errors.case_stage_id[0] }}</span>
                                    </div>
                                    <div class="col-3 mb-3">
                                        <label class="form-label">PR No.</label>
                                        <input type="text" class="form-control" placeholder="PR No."
                                            :class="{ 'is-invalid': errors.pr_no }" v-model="form.pr_no" />
                                        <span v-if="errors.pr_no" class="invalid-feedback">{{ errors.pr_no[0] }}</span>
                                    </div>
                                    <div class="col-3 mb-3">
                                        <label class="form-label">PR Date</label>
                                        <input type="date" class="form-control" placeholder="PR Date"
                                            :class="{ 'is-invalid': errors.pr_date }" v-model="form.pr_date"
                                            max="9999-12-31" />
                                        <span v-if="errors.pr_date" class="invalid-feedback">{{ errors.pr_date[0]
                                            }}</span>
                                    </div>
                                    <div class="col-3 mb-3">
                                        <label class="form-label">Holding Officer [Current]</label>
                                        <input type="text" class="form-control" placeholder="Holding Officer"
                                            :class="{ 'is-invalid': errors.holding_officer }"
                                            v-model="form.holding_officer" />
                                        <span v-if="errors.holding_officer" class="invalid-feedback">{{
                                            errors.holding_officer[0] }}</span>
                                    </div>
                                    <div class="col-3 mb-3">
                                        <label class="form-label">Police Station</label>
                                        <!-- <select class="form-control" :class="{'is-invalid': errors.police_station_id}" v-model="form.police_station_id" @change="getDivision($event)">
                                            <option value="">Select Police Station</option>
                                            <option v-for="police_station, key in police_stations" :key="key" :value="police_station.police_station_id"> {{ police_station.police_station }}</option>
                                        </select> -->

                                        <search :class="{ 'is-invalid': errors.police_station_id }"
                                            :customClass="{ 'is-invalid': errors.police_station_id }"
                                            :initialize="form.police_station_id" id="police_station_id"
                                            label="police_station" placeholder="Select Police Station"
                                            :data="police_stations"
                                            @input="police_station => form.police_station_id = police_station"
                                            @selectsearch="getDivision(form.police_station_id)">
                                        </search>
                                        <span v-if="errors.police_station_id" class="invalid-feedback">{{
                                            errors.police_station_id[0] }}</span>
                                    </div>
                                    <div class="col-3 mb-3">
                                        <label class="form-label">Division</label>
                                        <!-- <select class="form-control" :class="{'is-invalid': errors.division_id}" v-model="form.division_id">
                                            <option value="">Select Division</option>
                                            <option v-for="division, key in divisions" :key="key" :value="division.division_id"> {{ division.division }}</option>
                                        </select> -->
                                        <search :class="{ 'is-invalid': errors.division_id }"
                                            :customClass="{ 'is-invalid': errors.division_id }"
                                            :initialize="form.division_id" id="division_id" label="division"
                                            placeholder="Select Division" :data="divisions" :disabled="true"
                                            @input="division => form.division_id = division">
                                        </search>
                                        <span v-if="errors.division_id" class="invalid-feedback">{{
                                            errors.division_id[0] }}</span>
                                    </div>
                                    <div class="col-3 mb-3">
                                        <label class="form-label">Sub Division</label>
                                        <!-- <select class="form-control" :class="{'is-invalid': errors.sub_division_id}" v-model="form.sub_division_id">
                                            <option value="">Select Sub Division</option>
                                            <option v-for="sub_division, key in sub_divisions" :key="key" :value="sub_division.sub_division_id"> {{ sub_division.sub_division }}</option>
                                        </select> -->
                                        <search :class="{ 'is-invalid': errors.sub_division_id }"
                                            :customClass="{ 'is-invalid': errors.sub_division_id }"
                                            :initialize="form.sub_division_id" id="sub_division_id" label="sub_division"
                                            placeholder="Select sub_division" :data="sub_divisions" :disabled="true"
                                            @input="sub_division => form.sub_division_id = sub_division">
                                        </search>
                                        <span v-if="errors.sub_division_id" class="invalid-feedback">{{
                                            errors.sub_division_id[0] }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="mb-2">
                        <div class="d-flex justify-content-between">
                            <div class="d-flex">
                                <h5 class="mb-0">Seized Property Details</h5>
                                <span class="text-danger">*</span>
                                <i class="ri-error-warning-line text-danger mx-1" v-if="errors.properties"></i>
                            </div>
                            <button v-if="formStatus" class="btn btn-sm btn-success" type="button"
                                @click.prevent="addProperty()"><i class="ri-add-line fs-18 lh-1"></i> Add</button>
                        </div>

                        <div class="row">
                            <div class="col-md-12">
                                <div class="table-responsive mt-2" style="overflow-y: scroll;">
                                    <table class="table text-nowrap table-bottom-border mb-4">
                                        <thead>
                                            <tr style="background-color: #f5f5f5;">
                                                <th>Property Type</th>
                                                <th>Property Description</th>
                                                <th>Kilogram</th>
                                                <th>Gram</th>
                                                <!-- <th>Quantity</th> -->
                                                <th>Seized Property Available</th>
                                                <th>Remarks If Property Not Available</th>
                                                <th>Valuable / Non Valuable</th>
                                                <th>Property Value</th>
                                                <th>Property Disposed</th>
                                                <th>Property Disposed Reason</th>
                                                <th>Disposed Date</th>
                                                <th>Incharge Officer</th>
                                                <th>If Any Remarks By Incharge Officer</th>
                                                <th>Auction Value</th>
                                                <th>Auction Date</th>
                                                <th>Property Photo</th>
                                                <th class="text-center">Delete</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <!-- try -->
                                            <tr v-for="property, key in form.properties" :key="key">
                                                <td>
                                                    <select class="form-select"
                                                        :class="{ 'is-invalid': property.errors.property_type_id }"
                                                        v-model="property.property_type_id"
                                                        @change="checkPropertyType(property)">
                                                        <option value="">Select Property Type</option>
                                                        <option v-for="property_type, key in property_types" :key="key"
                                                            :value="property_type.property_type_id"> {{
                                                                property_type.property_type }}</option>
                                                    </select>
                                                    <!-- {{property_types}} -->
                                                    <!-- <search
                                                        :class="{ 'is-invalid': property.errors.property_type_id }"
                                                        :customClass="{ 'is-invalid': property.errors.property_type_id }"
                                                        :initialize="property.property_type_id"
                                                        id="property_type_id"
                                                        label="property_type"
                                                        placeholder="Select Property Type"
                                                        :data=" property_types"
                                                        @input=" property_type1 => property.property_type_id = property_type1"
                                                    >
                                                    </search> -->
                                                    <span v-if="property.errors.property_type_id"
                                                        class="invalid-feedback">{{ property.errors.property_type_id[0]
                                                        }}</span>
                                                </td>
                                                <td>
                                                    <input type="text" class="form-control"
                                                        placeholder="Property Description"
                                                        :class="{ 'is-invalid': property.errors.property_description }"
                                                        v-model="property.property_description" style="width: 155px;" maxlength="255" />
                                                    <span v-if="property.errors.property_description"
                                                        class="invalid-feedback">{{
                                                            property.errors.property_description[0] }}</span>
                                                </td>
                                                <td>
                                                    <input type="text" class="form-control" placeholder="Kilogram"
                                                        :class="{ 'is-invalid': property.errors.kilogram }"
                                                        v-model="property.kilogram" style="width: 155px;"
                                                        :disabled="validatePropertyType(property)"
                                                        @keypress="isNumber($event)" @copy.prevent @paste.prevent />
                                                    <span v-if="property.errors.kilogram" class="invalid-feedback">{{
                                                        property.errors.kilogram[0] }}</span>
                                                </td>
                                                <td>
                                                    <input type="text" class="form-control" placeholder="Grams"
                                                        :class="{ 'is-invalid': property.errors.grams }"
                                                        v-model="property.grams" style="width: 155px;"
                                                        :disabled="validatePropertyType(property)"
                                                        @keypress="isNumber($event)" @copy.prevent @paste.prevent />
                                                    <span v-if="property.errors.grams" class="invalid-feedback">{{
                                                        property.errors.grams[0] }}</span>
                                                </td>
                                                <!-- <td>
                                                    <input
                                                        type="text"
                                                        class="form-control"
                                                        :placeholder="getMeasuringUnit(property)"
                                                        :class="{ 'is-invalid': property.errors.grams }"
                                                        v-model="property.grams "
                                                        style="width: 155px;"
                                                        :disabled="validatePropertyType(property)"
                                                    />
                                                    <span v-if="property.errors.grams" class="invalid-feedback">{{ property.errors.grams[0] }}</span>
                                                </td> -->
                                                <td>
                                                    <select class="form-control"
                                                        :class="{ 'is-invalid': property.errors.siezed_property_available }"
                                                        v-model="property.siezed_property_available"
                                                        @change="showColumn('remarks', property.siezed_property_available, property)">
                                                        <!-- <option value="null">Select Yes/No</option>  -->
                                                        <option value="1">Yes</option>
                                                        <option value="0">No</option>
                                                    </select>
                                                    <span v-if="property.errors.siezed_property_available"
                                                        class="invalid-feedback">{{
                                                            property.errors.siezed_property_available[0] }}</span>
                                                </td>
                                                <td>
                                                    <input type="text" class="form-control" placeholder="Remarks"
                                                        :class="{ 'is-invalid': property.errors.remarks_if_property_not_available }"
                                                        v-model="property.remarks_if_property_not_available"
                                                        :disabled="property.siezed_property_available == '1' || property.siezed_property_available == null" />
                                                    <span v-if="property.errors.remarks_if_property_not_available"
                                                        class="invalid-feedback">{{
                                                            property.errors.remarks_if_property_not_available[0] }}</span>
                                                </td>
                                                <td>
                                                    <select class="form-control"
                                                        :class="{ 'is-invalid': property.errors.valuable_non_valuable }"
                                                        v-model="property.valuable_non_valuable"
                                                        @change="showColumn('valuable', property.valuable_non_valuable, property)">
                                                        <option value="">Select</option>
                                                        <option value="Valuable">Valuable</option>
                                                        <option value="Non-Valuable">Non-Valuable</option>
                                                    </select>
                                                    <span v-if="property.errors.valuable_non_valuable"
                                                        class="invalid-feedback">{{
                                                            property.errors.valuable_non_valuable[0] }}</span>
                                                </td>
                                                <td>
                                                    <input type="number" min="0" oninput="validity.valid||(value='');"
                                                        class="form-control" placeholder="Property Value"
                                                        :class="{ 'is-invalid': property.errors.property_value }"
                                                        style="width: 116px;" v-model="property.property_value"
                                                        :disabled="property.valuable_non_valuable == 'Non-Valuable' || property.valuable_non_valuable == ''" />
                                                    <span v-if="property.errors.property_value"
                                                        class="invalid-feedback">{{ property.errors.property_value[0]
                                                        }}</span>
                                                </td>
                                                <td>
                                                    <select class="form-control"
                                                        :class="{ 'is-invalid': property.errors.disposed }"
                                                        v-model="property.disposed"
                                                        @change="getDisposedStatus1('property_disposed', property.disposed, property)">
                                                        <!-- <option value="">Select Property Disposed</option> -->
                                                        <option value="1">Yes</option>
                                                        <option value="0">No</option>
                                                    </select>
                                                    <span v-if="property.errors.disposed" class="invalid-feedback">{{
                                                        property.errors.disposed[0] }}</span>
                                                </td>
                                                <td>
                                                    <select class="form-control"
                                                        v-model="property.property_disposal_stage_id"
                                                        :class="{ 'is-invalid': property.errors.property_disposal_stage_id }"
                                                        style="width: auto;"
                                                        :disabled="property.disposed == '0' || property.disposed == ''"
                                                        @change="getDisposedStatus('auction', property.property_disposal_stage_id, property)">
                                                        <option value="null">Select Property Disposal</option>
                                                        <option v-for="property_disposal, key in property_disposals"
                                                            :key="key"
                                                            :value="property_disposal.property_disposal_stage_id"> {{
                                                                property_disposal.property_disposal_stage }}</option>
                                                    </select>
                                                    <span v-if="property.errors.property_disposal_stage_id"
                                                        class="invalid-feedback">{{
                                                            property.errors.property_disposal_stage_id[0] }}</span>
                                                </td>
                                                <td>
                                                    <input type="date" class="form-control"
                                                        :class="{ 'is-invalid': property.errors.disposed_date }"
                                                        v-model="property.disposed_date" max="9999-12-31" />
                                                    <span v-if="property.errors.disposed_date"
                                                        class="invalid-feedback">{{ property.errors.disposed_date[0]
                                                        }}</span>
                                                </td>
                                                <td>
                                                    <input type="text" class="form-control"
                                                        placeholder="Incharge Officer"
                                                        :class="{ 'is-invalid': property.errors.officer_incharge }"
                                                        style="width: 124px;" v-model="property.officer_incharge" />
                                                    <span v-if="property.errors.officer_incharge"
                                                        class="invalid-feedback">{{ property.errors.officer_incharge[0]
                                                        }}</span>
                                                </td>
                                                <td>
                                                    <input type="text" class="form-control"
                                                        placeholder="Remarks By Incharge Officer"
                                                        :class="{ 'is-invalid': property.errors.instructions_by_officer }"
                                                        v-model="property.instructions_by_officer" />
                                                    <span v-if="property.errors.instructions_by_officer"
                                                        class="invalid-feedback">{{
                                                            property.errors.instructions_by_officer[0] }}</span>
                                                </td>
                                                <td>
                                                    <input type="number" class="form-control"
                                                        placeholder="Auction Value"
                                                        oninput="validity.valid||(value='');" style="width: 109px;"
                                                        :class="{ 'is-invalid': property.errors.auction_sold_value }"
                                                        v-model="property.auction_sold_value"
                                                        :disabled="property.auction_status == false || !property.property_disposal_stage_id || property.disposed == '0' || property.disposed == ''" />
                                                    <span v-if="property.errors.auction_sold_value"
                                                        class="invalid-feedback">{{
                                                            property.errors.auction_sold_value[0] }}</span>
                                                </td>
                                                <td>
                                                    <input type="date" class="form-control"
                                                        :class="{ 'is-invalid': property.errors.auction_sold_date }"
                                                        v-model="property.auction_sold_date" max="9999-12-31"
                                                        :disabled="property.auction_status == false || !property.property_disposal_stage_id" />
                                                    <span v-if="property.errors.auction_sold_date"
                                                        class="invalid-feedback">{{ property.errors.auction_sold_date[0]
                                                        }}</span>
                                                </td>
                                                <td>
                                                    <div class="mb-1">
                                                        <input type="file" class="form-control"
                                                            :class="{ 'is-invalid': property.errors.property_photo }"
                                                            ref="file" @change="getPropertyPhoto($event, property)" />
                                                        <span v-if="property.errors.property_photo"
                                                            class="invalid-feedback">{{
                                                                property.errors.property_photo[0] }}</span>
                                                    </div>
                                                    <div v-if="property.property_photo">
                                                        <a v-if="property?.url" :href="property?.url" target="_blank">{{
                                                            property.property_photo_name }}</a>
                                                        <a v-else :href="property?.property_photo" target="_blank">{{
                                                            property.property_photo_name }}</a>
                                                    </div>
                                                </td>
                                                <td class="text-center">
                                                    <nav>
                                                        <a href="javascript:void(0)" class="text-danger"
                                                            @click="deleteRow(property, key)"><i
                                                                class="ri-delete-bin-6-line fs-18 lh-1"></i></a>
                                                    </nav>
                                                </td>
                                            </tr>
                                            <!-- end -->                                            
                                        </tbody>
                                    </table>
                                </div>
                                <span class="text-danger small" v-if="errors.properties">{{ errors?.properties[0] }}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-footer text-end">
                    <router-link v-if="status" to="/muddemal" type="button" class="btn btn-danger me-2"><i
                            class="ri-arrow-left-line me-1"></i>Back</router-link>
                    <button class="btn btn-primary" type="button" @click.prevent="submitForm()">
                        <span v-if="status"> <i class="ri-save-line me-1"></i> Submit</span>
                        <span v-else> <i class="ri-save-line me-1"></i> Update </span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import Search from "@/components/Search.vue";
export default {
    name: "MuddemaalCreate",
    components: {
        Search,
    },
    data() {
        return {
            status: true,
            is_disabled: true,
            form: {
                muddemal_id: "",
                pf_number: "",
                pf_date: "",
                year: "",
                crime_no_sec: "",
                number_type: 'CRIME_NO',
                section_of_law: "",
                investigation_officer: "",
                siezed_from_whom: "",
                siezed_from_place: "",
                cc_sc_no: "",
                case_stage_id: "",
                pr_no: "",
                pr_date: "",
                holding_officer: "",
                police_station_id: "",
                sub_division_id: "",
                division_id: "",

                properties: [],
                deleted_properties: [],
            },

            property1: {
                property_detail_id: "",
                property_type_id: "",
                property_description: "",
                siezed_property_available: 1,
                remarks_if_property_not_available: "",
                valuable_non_valuable: "",
                property_value: "",
                property_photo: "",
                image: "",
                property_type: "",
            },
            formStatus: true,
            errors: [],
            case_stages: [],
            police_stations: [],
            divisions: [],
            sub_divisions: [],
            property_types: [],
            property_disposals: [],
            show_remarks: false,
            property_value: false,
            property_disposed: false,
            auction: false,
            years: [],
            number_type_label: 'Crime No.'
        };
    },
    beforeRouteEnter(to, from, next) {
        next((vm) => {
            if (to.name == "MuddemaalCreate") {
                vm.$refs.pf_number.focus();
                if (!vm.form.properties.length) {
                    vm.addProperty();
                }
            } else {
                vm.status = false;
                let uri = { uri: "getMuddemal", data: { muddemal_id: to.params.muddemal_id } };
                vm.$store
                    .dispatch("post", uri)
                    .then(function (response) {
                        vm.form = response.data.data;
                        vm.form.deleted_properties = [];
                        if (!vm.form.properties.length) {
                            vm.addProperty();
                        }

                        if (vm.form.properties.length) {
                            vm.form.properties.filter(function (ele) {
                                if (ele.disposed) {
                                    vm.property_disposed = true;
                                }
                            });
                        }
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            }
        });
    },
    mounted() {
        let vm = this;
        vm.getCaseStages();
    },

    watch: {
        "form.number_type": function (val) {
            switch (val) {
                case 'CRIME_NO': this.number_type_label = 'Crime No.';
                    break;
                case 'UDR_NO': this.number_type_label = 'UDR No.';
                    break;
                case 'NCR_NO': this.number_type_label = 'NCR No.';
                    break;
                case 'C_MISC': this.number_type_label = 'C Misc. No.';
                    break;
                default: this.number_type_label = 'Crime No.'
            }
        }
    },
    methods: {
        checkPropertyType(property) {
            return this.validatePropertyType(property)
        },
        validatePropertyType(property) {
            let vm = this
            let pr = this.property_types.filter(function (element) {
                return element.property_type_id == property.property_type_id
            })
            if (pr.length) {
                if (pr[0].property_type == 'Others') {
                    property.valuable_non_valuable = 'Non-Valuable';
                } else {
                    property.valuable_non_valuable = 'Valuable';
                }
                if (pr[0].property_type == 'Gold' || pr[0].property_type == 'Silver' || pr[0].property_type == 'Drugs') {
                    return false
                } else {
                    property.kilogram = ''
                    property.grams = ''
                }
            }
            return true
        },
        getYearFromDate(date) {
            let vm = this;
            vm.form.year = new Date(date).getFullYear();
        },
        showColumn(key, value, property) {
            let vm = this;
            if (key == "remarks" && value == 1) {
                property.remarks_if_property_not_available = "";
                vm.show_remarks = true;
            } else {
                vm.show_remarks = false;
            }

            if (key == "valuable" && value == "Valuable") {
                vm.property_value = true;
            } else {
                vm.property_value = false;
                property.property_value = "";
            }
        },
        getDisposedStatus(key, value, property) {
            let vm = this;

            if (key == "auction") {
                let disposal = vm.property_disposals.filter(function (ele) {
                    return ele.property_disposal_stage_id == value;
                });
                console.log(disposal);
                if (disposal.length && disposal[0].property_disposal_stage == "Auction") {
                    console.log("auction");
                    vm.auction = true;
                    vm.property_disposed = true;
                    property.auction_status = true;
                } else {
                    vm.auction = false;
                    vm.property_disposed = true;
                    property.auction_status = false;
                    property.auction_sold_value = null;
                    property.auction_sold_date = null;
                }
            }
        },
        getDisposedStatus1(key, value, property) {
            let vm = this;
            if (key == "property_disposed" && value == true) {
                vm.property_disposed = true;
            } else {
                vm.property_disposed = false;
                property.property_disposal_stage_id = "null";
                property.auction_status = false;
                property.auction_sold_value = null;
                property.auction_sold_date = null;
            }
        },

        addProperty() {
            let vm = this
            if (vm.form.properties.length == 0) {
                vm.form.properties.push({
                    property_detail_id: "",
                    property_type_id: "",
                    property_description: "",
                    siezed_property_available: 1,
                    remarks_if_property_not_available: "",
                    valuable_non_valuable: "",
                    property_value: "",
                    property_photo: "",
                    property_photo_name: "",
                    image: "",
                    property_type: "",
                    officer_incharge: "",
                    disposed: 0,
                    property_disposal_stage_id: null,
                    instructions_by_officer: "",
                    auction_sold: null,
                    disposed_date: null,
                    auction_sold_value: null,
                    auction_sold_date: null,
                    errors: [],
                });
            } else {
                let previous_property = vm.form.properties[vm.form.properties.length - 1]
                previous_property.errors = [];
                if (!previous_property.property_type_id || !previous_property.property_description) {
                    if (previous_property.property_type_id == "") {
                        previous_property.errors.property_type_id = ["property_type_id field cannot be empty"];
                    }
                    if (previous_property.property_description == "") {
                        previous_property.errors.property_description = ["property_description field cannot be empty"];
                    }
                    if (previous_property.disposed && !previous_property.property_disposal_stage_id) {
                        previous_property.errors.property_disposal_stage_id = ["property disposed reason cannot be empty"];
                    }
                    if (previous_property.disposed && !previous_property.disposed_date) {
                        previous_property.errors.disposed_date = ["disposed date field is required"];
                    }
                    if (previous_property.disposed && !previous_property.officer_incharge) {
                        previous_property.errors.officer_incharge = ["officer incharge field is required"];
                    }
                    if (previous_property.property_type_id) {
                        let vm = this
                        let pr = this.property_types.filter(function (element) {
                            return element.property_type_id == previous_property.property_type_id
                        })
                        if (pr.length) {
                            if ((pr[0].property_type == 'Gold' || pr[0].property_type == 'Silver' || pr[0].property_type == 'Drugs') && !previous_property.kilogram) {
                                previous_property.errors.kilogram = ["kilogram field cannot be empty"]
                            } else {
                                previous_property.errors.kilogram = ""
                            }
                            if ((pr[0].property_type == 'Gold' || pr[0].property_type == 'Silver' || pr[0].property_type == 'Drugs') && !previous_property.grams) {
                                previous_property.errors.grams = ["grams field cannot be empty"]
                            } else {
                                previous_property.errors.grams = ""
                            }
                        }
                    }
                    if (previous_property.siezed_property_available == "0" && !previous_property.remarks_if_property_not_available) {
                        previous_property.errors.remarks_if_property_not_available = ["remarks if property not available cannot be empty"];
                    }
                    if (previous_property.valuable_non_valuable == "Valuable" && !previous_property.property_value) {
                        if (previous_property.property_value == 0) {

                        } else {
                            previous_property.errors.property_value = ["property value cannot be empty"];
                        }
                    }
                    if (previous_property.disposed == "true" && previous_property.property_disposal_stage_id == null) {
                        previous_property.errors.property_disposal_stage_id = ["property disposal stage id cannot be empty"];
                    }
                    if (previous_property.disposed == "true" && !previous_property.disposed_date) {
                        previous_property.errors.disposed_date = ["disposed date cannot be empty"];
                    }
                    if (previous_property.property_disposal_stage_id) {
                        let disposal = vm.property_disposals.filter(function (ele) {
                            return ele.property_disposal_stage_id == previous_property.property_disposal_stage_id;
                        });
                        if (disposal.length && disposal[0].property_disposal_stage == "Auction") {
                            if (previous_property.auction_sold == null) {
                                previous_property.errors.auction_sold = ["auction value cannot be empty"];
                            }
                            if (previous_property.auction_sold_value == null) {
                                previous_property.errors.auction_sold_value = ["auction value cannot be empty"];
                            }
                            if (!previous_property.auction_sold_date) {
                                previous_property.errors.auction_sold_date = ["auction date cannot be empty"];
                            }
                        }
                    }
                    if (previous_property.valuable_non_valuable == '') {
                        previous_property.errors.valuable_non_valuable = ['Valuable/Non-Valuable field cannot be empty.']
                    }
                }

                if (previous_property.siezed_property_available) {
                    if (previous_property.siezed_property_available == "0" && !previous_property.remarks_if_property_not_available) {
                        previous_property.errors.remarks_if_property_not_available = ["remarks if property not available cannot be empty"];
                    }
                }
                if (previous_property.valuable_non_valuable) {
                    if (previous_property.valuable_non_valuable == "Valuable" && !previous_property.property_value) {
                        if (previous_property.property_value == 0) {

                        } else {
                            previous_property.errors.property_value = ["property value cannot be empty"];
                        }
                    }
                }
                if (previous_property.disposed) {
                    if (previous_property.disposed == "true" && previous_property.property_disposal_stage_id == null) {
                        previous_property.errors.property_disposal_stage_id = ["property disposal stage id cannot be empty"];
                    }
                    if (previous_property.disposed == "true" && !previous_property.disposed_date) {
                        previous_property.errors.disposed_date = ["disposed date cannot be empty"];
                    }
                }
                if (previous_property.property_disposal_stage_id) {
                    let disposal = vm.property_disposals.filter(function (ele) {
                        return ele.property_disposal_stage_id == previous_property.property_disposal_stage_id;
                    });
                    if (disposal.length && disposal[0].property_disposal_stage == "Auction") {
                        if (previous_property.auction_sold == null) {
                            previous_property.errors.auction_sold = ["auction sold cannot be empty"];
                        }
                        if (previous_property.auction_sold_value == null) {
                            previous_property.errors.auction_sold_value = ["auction sold value cannot be empty"];
                        }
                        if (!previous_property.auction_sold_date) {
                            previous_property.errors.auction_sold_date = ["auction sold date cannot be empty"];
                        }
                    }
                }
                if (!Object.keys(previous_property.errors).length) {
                    vm.form.properties.push({
                        property_detail_id: "",
                        property_type_id: "",
                        property_description: "",
                        siezed_property_available: 1,
                        remarks_if_property_not_available: "",
                        valuable_non_valuable: "",
                        property_value: "",
                        property_photo: "",
                        property_photo_name: "",
                        image: "",
                        property_type: "",
                        property_disposal_stage_id: null,
                        disposed: 0,
                        disposed_date: null,
                        officer_incharge: "",
                        instructions_by_officer: "",
                        auction_sold: null,
                        auction_sold_value: null,
                        auction_sold_date: null,
                        errors: [],
                    });
                }
            }
        },
        getDivision(police_station_id) {
            let vm = this;
            let police_Station = vm.police_stations.filter(function (ele) {
                return ele.police_station_id == police_station_id;
            });
            if (police_Station.length) {
                vm.form.division_id = police_Station[0]?.division?.division_id;
                vm.form.sub_division_id = police_Station[0]?.sub_division?.sub_division_id;
            }
        },
        getProperty(property) {
            this.property.property_type = property.property_type;
            console.log(this.property.property_type);
        },
        submitForm() {
            let vm = this;
            if (vm.status) {
                vm.addForms();
            } else {
                vm.updateForms();
            }
        },
        getCaseStages() {
            let vm = this;
            vm.$store
                .dispatch("post", { uri: "getCaseStages" })
                .then(function (response) {
                    vm.case_stages = response.data.data;
                    vm.getPoliceStations();
                })
                .catch(function (error) {
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
        },
        getPoliceStations() {
            console.log("sttaus", this.status)
            let vm = this;
            vm.$store
                .dispatch("post", { uri: "getUserPoliceStations" })
                .then(function (response) {
                    vm.police_stations = response.data.data;
                    if (vm.police_stations?.length && vm.status) {
                        vm.form.police_station_id = vm.police_stations[0].police_station_id;
                        vm.getDivision(vm.form.police_station_id)
                    }
                    vm.getDivisions();
                })
                .catch(function (error) {
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
        },
        getDivisions() {
            let vm = this;
            vm.$store
                .dispatch("post", { uri: "getDivisions" })
                .then(function (response) {
                    vm.divisions = response.data.data;
                    vm.getSubDivisions();
                })
                .catch(function (error) {
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
        },
        getSubDivisions() {
            let vm = this;
            vm.$store
                .dispatch("post", { uri: "getSubDivisions" })
                .then(function (response) {
                    vm.sub_divisions = response.data.data;
                    vm.getPropertyTypes();
                })
                .catch(function (error) {
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
        },
        getPropertyTypes() {
            let vm = this;
            vm.$store
                .dispatch("post", { uri: "getPropertyTypes" })
                .then(function (response) {
                    vm.property_types = response.data.data;
                    vm.getPropertyDisposals();
                })
                .catch(function (error) {
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
        },
        getPropertyDisposals() {
            let vm = this;
            let uri = { uri: "getPropertyDisposalStages" };
            vm.$store
                .dispatch("post", uri)
                .then(function (response) {
                    vm.property_disposals = response.data.data;
                })
                .catch(function (error) {
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
        },
        addRow() {
            alert(' testing');
            let vm = this;
            vm.errors = [];
            if (
                vm.property.property_type_id == "" ||
                vm.property.property_description == "" ||
                vm.property.siezed_property_available == null ||
                vm.property.remarks_if_property_not_available == "" ||
                vm.property.valuable_non_valuable == "" ||
                vm.property.property_value == ""
            ) {
                if (vm.property.property_type_id == "") {
                    vm.errors.property_type_id = ["Property type cannot be empty"];
                }
                if (vm.property.property_description == "") {
                    vm.errors.property_description = ["Property Description cannot be empty"];
                }
                if (vm.property.siezed_property_available == null) {
                    vm.errors.siezed_property_available = ["Siezed property available length cannot be empty"];
                }
                if (vm.property.remarks_if_property_not_available == "") {
                    vm.errors.remarks_if_property_not_available = ["remarks if property not available cannot be empty"];
                }
                if (vm.property.valuable_non_valuable == "") {
                    vm.errors.valuable_non_valuable = ["valuable or non valuable cannot be empty"];
                }
                if (vm.property.property_value == "") {
                    vm.errors.property_value = ["Property value cannot be empty"];
                }
                // if(vm.property.property_photo == ""){
                //     vm.errors.property_photo = ["Property photo cannot be empty"];
                // }
            } else {
                let property_type = vm.property_types.filter(function (ele) {
                    return ele.property_type_id == vm.property.property_type_id;
                });
                if (property_type.length) {
                    vm.property.property_type = property_type[0];
                }
                let data = {
                    property_detail_id: "",
                    property_type_id: vm.property.property_type_id,
                    property_description: vm.property.property_description,
                    siezed_property_available: vm.property.siezed_property_available,
                    remarks_if_property_not_available: vm.property.remarks_if_property_not_available,

                    valuable_non_valuable: vm.property.valuable_non_valuable,
                    property_value: vm.property.property_value,
                    property_photo: vm.property.property_photo,
                    image: vm.property.image,
                    property_type: vm.property.property_type,
                };
                vm.form.properties.push(data);
                vm.discardForm();
            }
        },
        updateRow() {
            let vm = this;

            vm.errors = [];
            if (
                vm.property.property_type_id == "" ||
                vm.property.property_description == "" ||
                vm.property.siezed_property_available == null ||
                vm.property.remarks_if_property_not_available == "" ||
                vm.property.valuable_non_valuable == "" ||
                vm.property.property_value == ""
            ) {
                if (vm.property.property_type_id == "") {
                    vm.errors.property_type_id = ["Property type cannot be empty"];
                }
                if (vm.property.property_description == "") {
                    vm.errors.property_description = ["Property Description cannot be empty"];
                }
                if (vm.property.siezed_property_available == null) {
                    vm.errors.siezed_property_available = ["Siezed property available length cannot be empty"];
                }
                if (vm.property.remarks_if_property_not_available == "") {
                    vm.errors.remarks_if_property_not_available = ["remarks if property not available cannot be empty"];
                }
                if (vm.property.valuable_non_valuable == "") {
                    vm.errors.valuable_non_valuable = ["valuable or non valuable cannot be empty"];
                }
                if (vm.property.property_value == "") {
                    vm.errors.property_value = ["Property value cannot be empty"];
                }
                // if(vm.property.property_photo == ""){
                //     vm.errors.property_photo = ["Property photo cannot be empty"];
                // }
            } else {
                let property_type = vm.property_types.filter(function (ele) {
                    return ele.property_type_id == vm.property.property_type_id;
                });
                if (property_type.length) {
                    vm.property.property_type = property_type[0];
                }
                let data = {
                    property_detail_id: "",
                    property_type_id: vm.property.property_type_id,
                    property_description: vm.property.property_description,
                    siezed_property_available: vm.property.siezed_property_available,
                    remarks_if_property_not_available: vm.property.remarks_if_property_not_available,
                    valuable_non_valuable: vm.property.valuable_non_valuable,
                    property_value: vm.property.property_value,
                    property_photo: vm.property.property_photo,
                    image: vm.property.image,
                    property_type: vm.property.property_type,
                };
                vm.form.properties.splice(vm.property.key, 1, data);
                vm.discardForm();
            }
        },
        discardForm() {
            let vm = this;
            vm.property.property_type_id = "";
            vm.property.property_description = "";
            vm.property.siezed_property_available = null;
            vm.property.remarks_if_property_not_available = "";
            vm.property.valuable_non_valuable = "";
            vm.property.property_value = "";
            vm.property.property_photo = "";
            vm.formStatus = true;
            vm.$refs.pf_number.focus();
            vm.errors = [];
        },
        editRow(forms, key) {
            let vm = this;
            vm.property = forms;
            vm.formStatus = false;
            vm.property.key = key;
            vm.errors = [];
        },
        deleteRow(property, key) {
            let vm = this;
            if (confirm("Do You want to Delete this Record?..")) {
                vm.form.deleted_properties.push(property.property_id), vm.form.properties.splice(key, 1);
                // vm.discardForm()
            }
        },
        addMuddemal() {
            let vm = this;
            if (vm.form.properties.length == 1) {
                let property = vm.form.properties[0];
                if (!property.property_type_id && !property.property_description && !property.siezed_property_available && !property.remarks_if_property_not_available && !property.valuable_non_valuable && !property.property_value) {
                    vm.form.properties = [];
                }
                if (property.siezed_property_available == "Valuable" && !property.property_value) {
                    if (property.property_value == 0) {

                    } else {
                        property.errors.property_value = ["property value cannot be empty"];
                    }
                }
            }
            let loader = vm.$loading.show();
            let uri = { uri: "addMuddemal", data: vm.form };
            vm.$store
                .dispatch("post", uri)
                .then(function () {
                    loader.hide();
                    vm.$store.dispatch("success", "Muddemal Added successfully");
                    vm.$router.push("/muddemal");
                })
                .catch(function (error) {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
        },

        updateMuddemal() {
            let vm = this;
            if (vm.form.properties.length == 1) {
                let property = vm.form.properties[0];
                if (!property.property_type_id && !property.property_description && !property.siezed_property_available && !property.remarks_if_property_not_available && !property.valuable_non_valuable && !property.property_value) {
                    vm.form.properties = [];
                }
            }
            let loader = vm.$loading.show();
            let uri = { uri: "updateMuddemal", data: vm.form };
            vm.$store
                .dispatch("post", uri)
                .then(function () {
                    loader.hide();
                    vm.$store.dispatch("success", "Muddemal Updated successfully");
                    vm.$router.push("/muddemal");
                })
                .catch(function (error) {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    if (vm.errors) {
                        vm.addProperty();
                    }
                    vm.$store.dispatch("error", error.response.data.message);
                });
        },

        getPropertyPhoto(e, property) {
            let vm = this;
            property.image = e.target.files[0];
            property.property_photo_name = property.image?.name;
            let reader = new FileReader();
            reader.readAsDataURL(property.image);
            reader.onload = (e) => {
                property.property_photo = e.target.result;
            };
            property.url = URL.createObjectURL(property.image);
        },
        addForms() {
            let vm = this;
            let errs = [];
            let property = {
                errors: [],
            };
            let prop_error_count = 0;
            if (vm.form.properties.length) {
                errs = [];
                vm.form.properties.filter(function (prop) {
                    if (prop.disposed_date == "") {
                        prop.disposed_date = null
                    }
                    if (prop.siezed_property_available == "" || prop.siezed_property_available == "null") {
                        prop.siezed_property_available = null
                    }
                    prop.errors = [];
                    if (
                        !prop.property_type_id &&
                        !prop.property_description &&
                        // !prop.siezed_property_available &&
                        !prop.remarks_if_property_not_available &&
                        !prop.valuable_non_valuable &&
                        !prop.property_value &&
                        !prop.disposed &&
                        !prop.property_disposal_stage_id
                    ) {
                        vm.form.properties.pop(prop);
                    }
                    if (prop.property_type_id == "" && vm.form.properties.length != 0) {
                        prop.errors.property_type_id = ["property_type_id field cannot be empty"];
                    }
                    if (prop.property_type_id) {
                        let pr = vm.property_types.filter(function (element) {
                            return element.property_type_id == prop.property_type_id
                        })
                        if (pr.length) {
                            if ((pr[0].property_type == 'Gold' || pr[0].property_type == 'Silver' || pr[0].property_type == 'Drugs') && !prop.kilogram && !prop.grams) {
                                prop.errors.kilogram = ["kilogram field cannot be empty"]
                                prop.errors.grams = ["grams field cannot be empty"]
                            }
                        }
                    }
                    if (vm.form.properties.length && prop.property_description == "") {
                        prop.errors.property_description = ["property_description field cannot be empty"];
                    }
                    if (prop.siezed_property_available == "0" && !prop.remarks_if_property_not_available) {
                        prop.errors.remarks_if_property_not_available = ["remarks if property not available cannot be empty"];
                        errs.remarks_if_property_not_available = ["remarks if property not available cannot be empty"];
                    }
                    if (prop.valuable_non_valuable == "Valuable" && !prop.property_value) {
                        if (prop.property_value == 0) {

                        } else {
                            prop.errors.property_value = ["property value cannot be empty"];
                            errs.property_value = ["property value cannot be empty"];
                        }
                    }
                    if (prop.disposed == 1 && prop.property_disposal_stage_id == null) {
                        prop.errors.property_disposal_stage_id = ["property disposal stage id cannot be empty"];
                        errs.property_disposal_stage_id = ["property disposal stage id cannot be empty"];
                    }
                    if (prop.disposed == 1 && !prop.disposed_date) {
                        prop.errors.disposed_date = ["disposed date cannot be empty"];
                        errs.disposed_date = ["disposed date cannot be empty"];
                    }
                    if (prop.disposed == 1 && !prop.officer_incharge) {
                        prop.errors.officer_incharge = ["Officer incharge field cannot be empty"];
                        errs.officer_incharge = ["Officer incharge field cannot be empty"];
                    }
                    if (prop.property_disposal_stage_id) {
                        let disposal = vm.property_disposals.filter(function (ele) {
                            return ele.property_disposal_stage_id == prop.property_disposal_stage_id;
                        });
                        if (disposal.length && disposal[0].property_disposal_stage == "Auction") {
                            // if (prop.auction_sold == null) {
                            //     prop.errors.auction_sold = ["auction sold cannot be empty"];
                            //     errs.auction_sold = ["auction sold cannot be empty"];
                            // }
                            if (prop.auction_sold_value == null) {
                                prop.errors.auction_sold_value = ["auction sold value cannot be empty"];
                                errs.auction_sold_value = ["auction sold value cannot be empty"];
                            }
                            if (!prop.auction_sold_date) {
                                prop.errors.auction_sold_date = ["auction sold date cannot be empty"];
                                errs.auction_sold_date = ["auction sold date cannot be empty"];
                            }
                        }
                    }
                    prop_error_count = Object.keys(prop.errors)?.length;
                });
            }
            
            if (!Object.keys(errs)?.length) {
                if (prop_error_count != 0) {
                    prop_error_count = 0;
                    return;
                }
                let properties = [];
                properties.push(vm.form.properties);
                let data = new FormData();
                data.append("pf_number", this.form.pf_number);
                data.append("pf_date", this.form.pf_date);
                data.append("year", this.form.year);
                data.append("crime_no_sec", this.form.crime_no_sec);
                data.append("number_type", this.form.number_type);
                data.append("section_of_law", this.form.section_of_law);
                data.append("investigation_officer", this.form.investigation_officer);
                data.append("siezed_from_whom", this.form.siezed_from_whom);
                data.append("siezed_from_place", this.form.siezed_from_place);
                data.append("cc_sc_no", this.form.cc_sc_no);
                data.append("case_stage_id", this.form.case_stage_id);
                data.append("pr_no", this.form.pr_no);
                data.append("pr_date", this.form.pr_date);
                data.append("holding_officer", this.form.holding_officer);
                data.append("police_station_id", this.form.police_station_id);
                data.append("sub_division_id", this.form.sub_division_id);
                data.append("division_id", this.form.division_id);
                data.append("muddemal_status", this.form.muddemal_status);
                data.append("properties", JSON.stringify(vm.form.properties));

                let loader = vm.$loading.show();
                axios
                    .post(vm.$store.state.apiUrl + "api/addMuddemal", data, {
                        headers: {
                            "Content-Type": "multipart/form-data",
                            Authorization: "Bearer" + " " + vm.$store.getters.token,
                        },
                    })
                    .then((response) => {
                        loader.hide();
                        vm.$store.dispatch("success", "Muddemal Uploaded successfully");
                        vm.$router.push("/muddemal");
                    })
                    .catch(function (error) {
                        loader.hide();
                        vm.errors = error.response.data.errors;
                        if (vm.errors) {
                            if (vm.form.properties.length == 0) {
                                vm.addProperty();
                            }
                        }
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            } else {
                console.log(false);
            }
        },

        updateForms() {
            //pf number and crime number validation
            this.errors = [];
            let pattern = /^\d{4}\/\d{4}$/;
            let regex = new RegExp(pattern);
            let currentYear = new Date().getFullYear();
            let validation_result = regex.test(this.form.crime_no_sec);
            let flag = false;
            if (validation_result) {
                let year = parseInt(this.form.crime_no_sec.split('/')[1], 10);
                if (year > currentYear) {
                    this.errors.crime_no_sec = ['Invalid year in crime number']
                    flag = true;
                }
            } else {
                this.errors.crime_no_sec = ['Invalid crime number'];
                flag = true;
            }

            validation_result = regex.test(this.form.pf_number);
            if (validation_result) {
                let year = parseInt(this.form.pf_number.split('/')[1], 10);
                if (year > currentYear) {
                    this.errors.pf_number = ['Invalid year in property form number']
                    flag = true;
                }
            } else {
                this.errors.pf_number = ['Invalid property form number'];
                flag = true;
            }

            if (flag) return;
            //end of validation

            let vm = this;
            let errs = [];
            let property = {
                errors: [],
            };
            if (vm.form.properties.length) {
                errs = [];
                vm.form.properties.filter(function (prop) {
                    if (prop.disposed_date == "") {
                        prop.disposed_date = null
                    }
                    if (prop.siezed_property_available == "" || prop.siezed_property_available == "null") {
                        prop.siezed_property_available = null
                    }
                    prop.errors = [];
                    if (
                        !prop.property_type_id &&
                        !prop.property_description &&
                        !prop.siezed_property_available &&
                        !prop.remarks_if_property_not_available &&
                        !prop.valuable_non_valuable &&
                        !prop.property_value &&
                        !prop.disposed &&
                        !prop.property_disposal_stage_id
                    ) {
                        vm.form.properties.pop(prop);
                    }
                    if (prop.property_type_id) {
                        let pr = vm.property_types.filter(function (element) {
                            return element.property_type_id == prop.property_type_id
                        })
                        if (pr.length) {
                            if ((pr[0].property_type == 'Gold' || pr[0].property_type == 'Silver' || pr[0].property_type == 'Drugs') && !prop.kilogram) {
                                prop.errors.kilogram = ["kilogram field cannot be empty"]
                            } else {
                                prop.errors.kilogram = ""
                            }
                            if ((pr[0].property_type == 'Gold' || pr[0].property_type == 'Silver' || pr[0].property_type == 'Drugs') && !prop.grams) {
                                prop.errors.grams = ["grams field cannot be empty"]
                            } else {
                                prop.errors.grams = ""
                            }
                        }
                    }
                    if (prop.property_description == "") {
                        prop.errors.property_description = ["property_description field cannot be empty"];
                    }
                    if (prop.siezed_property_available == "0" && !prop.remarks_if_property_not_available) {
                        prop.errors.remarks_if_property_not_available = ["remarks if property not available cannot be empty"];
                        errs.remarks_if_property_not_available = ["remarks if property not available cannot be empty"];
                    }
                    if (prop.valuable_non_valuable == "Valuable" && !prop.property_value) {
                        if (prop.property_value == 0) {

                        } else {
                            prop.errors.property_value = ["property value cannot be empty"];
                            errs.property_value = ["property value cannot be empty"];
                        }
                    }
                    if (prop.disposed == 1 && prop.property_disposal_stage_id == null) {
                        prop.errors.property_disposal_stage_id = ["property disposal stage id cannot be empty"];
                        errs.property_disposal_stage_id = ["property disposal stage id cannot be empty"];
                    }
                    if (prop.disposed == 1 && !prop.disposed_date) {
                        prop.errors.disposed_date = ["disposed date cannot be empty"];
                        errs.disposed_date = ["disposed date cannot be empty"];
                    }
                    if (prop.property_disposal_stage_id) {
                        let disposal = vm.property_disposals.filter(function (ele) {
                            return ele.property_disposal_stage_id == prop.property_disposal_stage_id;
                        });
                        if (disposal.length && disposal[0].property_disposal_stage == "Auction") {
                            // if (prop.auction_sold == null) {
                            //     prop.errors.auction_sold = ["auction sold cannot be empty"];
                            //     errs.auction_sold = ["auction sold cannot be empty"];
                            // }
                            if (prop.auction_sold_value == null) {
                                prop.errors.auction_sold_value = ["auction sold value cannot be empty"];
                                errs.auction_sold_value = ["auction sold value cannot be empty"];
                            }
                            if (!prop.auction_sold_date) {
                                prop.errors.auction_sold_date = ["auction sold date cannot be empty"];
                                errs.auction_sold_date = ["auction sold date cannot be empty"];
                            }
                        }
                    }
                });
            }
            if (!Object.keys(errs)?.length) {
                let properties = [];
                properties.push(vm.form.properties);
                let data = new FormData();
                data.append("muddemal_id", this.form.muddemal_id);
                data.append("pf_number", this.form.pf_number);
                data.append("pf_date", this.form.pf_date);
                data.append("year", this.form.year);
                data.append("crime_no_sec", this.form.crime_no_sec);
                data.append("section_of_law", this.form.section_of_law);
                data.append("investigation_officer", this.form.investigation_officer);
                data.append("siezed_from_whom", this.form.siezed_from_whom);
                data.append("siezed_from_place", this.form.siezed_from_place);
                data.append("cc_sc_no", this.form.cc_sc_no);
                data.append("case_stage_id", this.form.case_stage_id);
                data.append("pr_no", this.form.pr_no);
                data.append("pr_date", this.form.pr_date);
                data.append("holding_officer", this.form.holding_officer);
                data.append("police_station_id", this.form.police_station_id);
                data.append("sub_division_id", this.form.sub_division_id);
                data.append("division_id", this.form.division_id);
                data.append("muddemal_status", this.form.muddemal_status);
                data.append("properties", JSON.stringify(vm.form.properties));
                data.append("deleted_properties", JSON.stringify(vm.form.deleted_properties));

                let loader = vm.$loading.show();
                axios
                    .post(vm.$store.state.apiUrl + "api/updateMuddemal", data, {
                        headers: {
                            "Content-Type": "multipart/form-data",
                            Authorization: "Bearer" + " " + vm.$store.getters.token,
                        },
                    })
                    .then((response) => {
                        loader.hide();
                        vm.$store.dispatch("success", "Muddemal Updated successfully");
                        vm.$router.push("/muddemal");
                    })
                    .catch(function (error) {
                        loader.hide();
                        vm.errors = error.response.data.errors;
                        if (vm.errors) {
                            if (vm.form.properties.length == 0) {
                                vm.addProperty();
                            }
                        }
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            }
        },
        isNumber(e) {
            const keysAllowed = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9','.'];
            const keyPressed = e.key;
            if (!keysAllowed.includes(keyPressed)) {
                e.preventDefault()
            }
        },
        // getMeasuringUnit(property){
        //     $unit = this.property_types.find(ele => {return ele.property_type_id == property.property_type_id}).measuring_unit;
        // }
    },
};
</script>

<style scoped>
.modal-img {
    width: -webkit-fill-available;
}

.modal-close {
    background-color: white;
    border-radius: 50%;
    padding: 5px;
}

input:focus,
select:focus {
    outline: none;
    box-shadow: 0 0 0 0 transparent;
}

.menu_icon {
    font-size: 20px;
    opacity: 0.85;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
</style>
